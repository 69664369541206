import { PolicyConfig } from 'AppProvider/ConfigManager/config';
import {
  useMobileContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';
import { TimeOffDeleteIcon } from 'Icons/TimeOffActionIcons/TimeOffDeleteIcon';
import { TimeOffDownloadIcon } from 'Icons/TimeOffActionIcons/TimeOffDownloadIcon';
import {
  Checkbox,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Tooltip,
  Typography,
  Upload
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { BaseButton, ButtonSimple } from 'components/Buttons';
import ComponentWithStatus from 'components/ComponentWithStatus';
import DateRangePicker from 'components/DateRangePicker';
import { SelectDropdown } from 'components/Dropdown';
import { useModalContext } from 'components/Modal/ModalProvider';
import { TORFormPlaceholder } from 'components/Placeholders';
import TimeRangePicker from 'components/TimeRangePicker';
import { getTitleSelects } from 'components/TitlesColumns/utils';
import ColFormItemComponent from 'components/UIComponents/Form/ColComponent';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import {
  FC,
  FocusEvent,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { EmployeeServices, TimeOffService } from 'services';
import { useAuth } from 'services/providers/AuthProvider';
import { settingModule, useCompanySettings } from 'settings';
import {
  notifyWithError,
  notifyWithErrorOnMaxSizeUploadFile
} from 'utils/notificationsUtils';
import { keyDownNumbersOnly } from 'utils/numberUtils';
import { onFormFinishFailedValidation } from 'utils/onSubmitForm';
import {
  adminRoles,
  allowPermision,
  getCurrentUserRole,
  userRoles
} from 'utils/userManagement';
import { onSubmitTimeOffRequestForm } from './onSubmit';
import {
  AdditionalFieldSchema,
  DaysAllowedType,
  TimeOffRequestFormProps,
  TimeOffStatisticsResultData
} from './types';
import {
  calculateStartEndDates,
  changeModalSubmitBtnText,
  filterPoliciesByGender,
  getDaysRequested,
  getListOfEmployees,
  getListOfMedicalLeaveTypes,
  getListOfTimeOffPolicies,
  getPolicyByName,
  isRequestEditable,
  isTimeBasedRequest,
  isUploadingEnabled,
  policiesWithUnfinish,
  prepareAdditionalFields,
  updateDateTimeValues,
  updateDatesValues,
  updateDaysAllowed
} from './utils';
import {
  allowedDateRangePickerSelection,
  allowedDateSelectionOnTimeBasedRequest,
  allowedTimeSelection,
  validateAdditionalFields,
  validateDates,
  validateSingleDate
} from './validations';
import { validateFileType } from 'components/UploadFile/utils';
import { EventsTypes } from 'pages/TimeManagement/Vacation/types';
dayjs.extend(isToday);

const TimeOffRequestForm: FC<TimeOffRequestFormProps> = ({
  context,
  scope,
  requestType,
  timeOffId,
  requestTypeOff,
  employeeId,
  setModalProps,
  setIsEdit,
  setViewRequestModal
}) => {
  const { Text } = Typography;
  const [form] = Form.useForm();
  const descriptionInputRef = useRef<any>();
  const additionalInputRef = useRef<any>();
  const [employeeProfile, setEmployeeProfile] = useState(null);
  const [selectedColleagueProfile, setSelectedColleagueProfile] =
    useState(null);
  const [selectedColleaguesPolicy, setSelectedColleaguesPolicy] =
    useState(null);
  const request = { requestType, scope, timeOffId, requestTypeOff };
  const { requestTypeOff: requestTimeOffType } = request;
  const dispatch = useDispatch();
  const { toggleModal } = useModalContext();
  const [statisticData, setStatisticData] = useState<any>(null);
  const [data, setData] = useState<any>(null);
  const [startDate, setStartDate] = useState<dayjs.Dayjs>(dayjs());
  const [endDate, setEndDate] = useState<dayjs.Dayjs>(null);
  const [daysRequested, setDaysRequested] = useState<string>(null);
  const [daysAllowed, setDaysAllowed] = useState<number>(null);
  const [timeOffRequestType, setTimeOffRequestType] = useState<string>(null);
  const [requestStatus, setRequestStatus] = useState<string>('Pending');
  const [status, setSetatus] = useState<string>('pending');

  const [additionalFieldsSchema, setAdditionalFieldsSchema] = useState<
    AdditionalFieldSchema[]
  >([]);
  const [daysAllowedType, setDaysAllowedType] = useState<DaysAllowedType>(
    DaysAllowedType.CalendarDays
  );
  const [fileList, setFileList] = useState<any>([]);
  const [isFileRequired, setIsFileRequired] = useState<boolean>(true);
  const maxFilesPerRequest = 5;
  const rootSelector = useSelector((state: RootState) => state);
  const onFinishFormActions = {
    dispatch,
    toggleModal,
    setIsEdit,
    setViewRequestModal
  };
  const { user } = useAuth();
  const { countryCode, forbidOffsetForFutureHours } = useCompanySettings();
  const isAdminRole = () =>
    allowPermision(getCurrentUserRole(user), [userRoles.Admin, userRoles.HR]);

  const getRequestApiEmployee = async () => {
    const statisticsRequests =
      employeeId && isAdminRole()
        ? await TimeOffService.getTimeOffStatisticsByEmployeeId(+employeeId)
        : await TimeOffService.getTimeOffStatistics();

    setData(statisticsRequests.data);
    setSetatus(scope === 'view' ? 'pending' : 'success');
  };

  //forgot badge policy allows user to take request only on the same day, therefore restrict employee ability to select other from popup
  const allowedDatePickerPopupSelection =
    ![selectedColleaguesPolicy, timeOffRequestType].includes('ForgotBadge') ||
    isAdminRole();
  let isRegularFormSubmit = true;
  //admin can select any values, user cannot select weekends
  const isRO = countryCode === 'RO';
  const isValidTimeOffDateSelected = (date: dayjs.Dayjs): boolean => {
    return isRO
      ? true
      : !isAdminRole()
        ? date.day() !== 0 && date.day() !== 6
        : true;
  };
  const EmployeesMap = useMemo(() => {
    const tempMap = new Map();
    getListOfEmployees(rootSelector).forEach((employee: any) => {
      tempMap.set(employee.id, employee);
    });
    return tempMap;
  }, [requestType !== 'personal']);

  const [isStartDateSelected, setIsStartDateSelected] = useState(true);

  const defaultRequestedType = {
    WorkFromHome: 1,
    ForgotBadge: 1,
    FirstDayOfSchool: 1,
    LastDayOfSchool: 1,
    Overtime: '00:00',
    PersonalLeave: '00:00',
    LeaveOffsetting: '00:00'
  };

  const specialFiltredRequests = PolicyConfig[
    countryCode
  ]?.DefaultTimeOffRequests.filter(
    request =>
      !request.isHidden &&
      (isAdminRole() ? true : !request.isAdminOnly) &&
      request.isSpecialRequest === true
  );
  const policiesFiltred = isAdminRole()
    ? specialFiltredRequests
    : filterPoliciesByGender(specialFiltredRequests, user?.gender);
  const specialRequests = policiesFiltred.map(request => request.request);

  const isInvalidRequestType = [
    'Overtime',
    'PersonalLeave',
    'LeaveOffsetting'
  ].includes(timeOffRequestType);

  //upload file extension
  const acceptFilesExtension =
    'image/jpeg, image/jpg, image/png, image/gif, image/bmp, image/webp, image/svg+xml, application/pdf';
  useEffect(() => {
    getRequestApiEmployee();
    EmployeeServices.getEmployeeProfile('false').then(profile => {
      setEmployeeProfile(profile.data);
    });
  }, [employeeId]);

  useLayoutEffect(() => {
    const getTimeOffRequestAndUpdateFieldsValues = () => {
      TimeOffService.getTimeOffRequest({
        requestId: timeOffId,
        extended: true
      }).then((res: any) => {
        setSetatus('success');
        const timeOffData = res.data.data;
        const isDraftRequest = timeOffData?.status === 'Draft';
        const isUnfinishedRequest = timeOffData.status === 'Unfinished';
        setRequestStatus(timeOffData?.status);
        timeOffData &&
          getPolicyByName(
            timeOffData.requestType,
            setAdditionalFieldsSchema,
            setDaysAllowedType
          ).then(({ additionalFields: result }: any) => {
            if (result && result.length) {
              result.map((fieldSchema: AdditionalFieldSchema) => {
                const additionalField = timeOffData.additionalFields.find(
                  (x: { name: string }) => x.name === fieldSchema.name
                );
                if (additionalField && fieldSchema.fieldType === 'date') {
                  additionalField.value = additionalField.value
                    ? dayjs(additionalField.value)
                    : null;
                }
                form.setFieldsValue({
                  [fieldSchema.name]: additionalField.value
                });
              });
            }
          });

        if (data && data.statistics && data.statistics.length) {
          const selectedPolicy = data.statistics.filter(
            policy => policy.statisticType === timeOffData.requestType
          );
          const daysLeft = selectedPolicy.length
            ? selectedPolicy[0].daysLeft
            : null;
          form.setFieldValue('daysAllowed', daysLeft || '∞');

          updateDaysAllowed(
            timeOffData.requestType,
            statisticData,
            requestStatus,
            setDaysAllowed
          );
        }

        if (timeOffData && timeOffData.attachmentUrls) {
          const files = timeOffData.attachmentUrls.split('©');
          form.setFieldValue('uploadFile', files);
          setFileList(
            files.map((f: string) => ({ name: f.split('/').at(-1) }))
          );
        }

        if (timeOffData.startDate) {
          setStartDate(dayjs(timeOffData.startDate));
        }

        if (timeOffData.endDate) {
          setEndDate(dayjs(timeOffData.endDate));
        }

        form.setFieldsValue({
          requestType: intl.formatMessage({ id: timeOffData.requestType }),
          dates: [
            dayjs(timeOffData.startDate),
            timeOffData.endDate ? dayjs(timeOffData.endDate) : null
          ],
          daysRequested: `${timeOffData.daysTaken ?? 0}`,
          notes: timeOffData.notes,
          draft: isDraftRequest,
          requestedForId: timeOffData.requestedForEmployee?.employeeId
        });

        setRequestStatus(timeOffData.status);
        setTimeOffRequestType(timeOffData.requestType);
        setIsFileRequired(isDraftRequest);
        updateDaysAllowed(
          // form.getFieldValue('requestType'),
          timeOffRequestType,
          data.statistics,
          data && data.statistics && data.statistics.length
            ? 'success'
            : 'pending',
          setDaysAllowed
        );
      });
    };

    updateDaysAllowed(
      // form.getFieldValue('requestType'),
      timeOffRequestType,
      data && data.statistics,
      data && data.statistics && data.statistics.length ? 'success' : 'pending',
      setDaysAllowed
    );
    //send statusat third element

    if (!timeOffId) {
      return;
    }

    if (data && data.statistics && data.statistics.length) {
      //send status   &&  status === 'success'
      setStatisticData(data.statistics);
    }

    //whenever we get statistics data, get TOR and initialize form values
    data && timeOffId && getTimeOffRequestAndUpdateFieldsValues();
  }, [data, timeOffId]);

  useEffect(() => {
    if (
      (requestTimeOffType || timeOffRequestType) &&
      scope !== 'draft' &&
      requestTimeOffType !== timeOffRequestType
    ) {
      getPolicyByName(
        requestTimeOffType || timeOffRequestType,
        setAdditionalFieldsSchema,
        setDaysAllowedType
      );
    }

    setModalProps &&
      setModalProps(state => {
        return {
          ...state,
          ...{
            displayFooterAdditionalButton: policiesWithUnfinish.includes(
              selectedColleaguesPolicy
            )
          }
        };
      });
  }, [timeOffRequestType]);

  useEffect(() => {
    if (scope === 'draft') {
      return;
    }

    form.setFieldsValue({
      daysAllowed: `${
        daysAllowed === null ? '∞' : daysAllowed
      } ${intl.formatMessage({
        id: 'days'
      })}`,
      daysRequested: daysRequested
        ? getDaysRequested(daysRequested)
        : defaultRequestedType[timeOffRequestType] ??
          defaultRequestedType[requestTimeOffType] ??
          null
    });
  }, [daysRequested, daysAllowed]);

  useEffect(() => {
    if (scope !== 'request' && scope !== 'ColleagueProfile') {
      return;
    }

    form.setFieldsValue({
      requestType: requestTimeOffType
        ? intl.formatMessage({ id: requestTimeOffType })
        : null // This should be tested if it's not breaking anything
    });
    setTimeOffRequestType(requestTimeOffType);
  }, [requestTimeOffType]);

  useEffect(() => {
    const dates = form.getFieldValue('dates');
    changeModalSubmitBtnText(requestStatus === 'Draft', intl);

    if (startDate && dates && dates.length) {
      return;
    }
  }, [requestStatus]);

  useEffect(() => {
    /* Flow description:
    if TOR is a single day request policy, set dates field to the same value of startDate
      else
         if it's timebased policy (leaveOffsetting, Overtime, PersonalLeave),
        then make sure that the current startDate is valid and set it as default, 
      else 
        set startDate to currentDate if value is valid, else set both startDate and endDate field to null
    */
    form.setFieldValue(
      'dates',
      isSingleDayRequestPolicy(
        requestType === 'colleagues' || requestType === 'special'
          ? selectedColleaguesPolicy
          : requestTimeOffType
      )
        ? [dayjs(), dayjs()]
        : isTimeBasedRequest(requestTimeOffType || timeOffRequestType)
          ? isValidTimeOffDateSelected(startDate)
            ? startDate
            : null
          : [isValidTimeOffDateSelected(startDate) ? startDate : null, null]
    );
  }, [selectedColleaguesPolicy, requestTimeOffType]);
  const { theme } = useThemeContext();
  const ButtonCustom = BaseButton(ButtonSimple);

  const currentDate = new Date();
  const currentHour = currentDate.getHours();
  const currentMinute = currentDate.getMinutes();

  const disabledMinutes = selectedHour => {
    let minutes = [];
    if (selectedHour === currentHour) {
      for (let i = currentMinute + 1; i < 60; i++) {
        minutes.push(i);
      }
    }

    return minutes;
  };

  const disabledTime = () => {
    let hours = [];
    for (let i = currentHour + 1; i < 24; i++) {
      hours.push(i);
    }

    return {
      disabledHours: () => hours,
      disabledMinutes: selectedHour => disabledMinutes(selectedHour)
    };
  };

  const isSingleDayRequestPolicy = timeOffReqType => {
    if (!timeOffReqType) return false;

    return (
      PolicyConfig[countryCode]?.DefaultTimeOffRequests.filter(
        policy => policy.request === timeOffReqType
      )[0].isSingleDayRequest ?? false
    );
  };

  const [filesToRemove, setFilesToRemove] = useState([]);

  const onRemoveFile = file => {
    const newFileList = fileList.filter(f => f.uid !== file.uid);
    setFilesToRemove((prevFilesToRemove = []) => [
      ...prevFilesToRemove,
      file.name
    ]);
    setFileList(newFileList);
    form.setFieldsValue({ uploadFile: newFileList });
  };

  const renderSwitch = (field: AdditionalFieldSchema, index: number) => {
    switch (field.fieldType) {
      case 'number':
        return (
          <Input
            ref={index === 0 ? additionalInputRef : undefined}
            onKeyDown={keyDownNumbersOnly}
            maxLength={field.maxLength || 10}
          />
        );
      case 'date':
        return <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />;
      case 'text':
        return (
          <Input
            ref={index === 0 ? additionalInputRef : undefined}
            maxLength={field.maxLength || 50}
          />
        );
      case 'timeRange':
        return (
          <TimeRangePicker
            format="HH:mm"
            style={{ width: '100%' }}
            popupClassName={`time-range-picker ${theme}`}
            disabledTime={
              !isAdminRole() &&
              forbidOffsetForFutureHours &&
              requestTypeOff === 'LeaveOffsetting'
                ? disabledTime
                : undefined
            }
            disabledDate={current => {
              if (requestType === 'personal') {
                return allowedTimeSelection(
                  current,
                  startDate,
                  requestTypeOff,
                  countryCode,
                  getCurrentUserRole(user),
                  scope,
                  selectedColleagueProfile,
                  user
                );
              }
            }}
            //when timepicker changes, update requested days

            onChange={() =>
              updateDateTimeValues(
                form.getFieldValue('timeRange'),
                form,
                setDaysRequested
              )
            }
            onCalendarChange={dates =>
              updateDateTimeValues(dates, form, setDaysRequested)
            }
          />
        );
      case 'select':
        return (
          <SelectDropdown placement_bottom={true} listHeight={158}>
            {getListOfMedicalLeaveTypes(rootSelector.general).map(
              (medicalLeaveType: any, index: number) => {
                const medicalLeaveTypeValues = getTitleSelects(
                  medicalLeaveType,
                  'medicalType'
                );
                return (
                  <Select.Option key={index} value={medicalLeaveType}>
                    <Tooltip placement="top" title={medicalLeaveTypeValues}>
                      <Text className="ellipsis-cell-text">
                        {medicalLeaveTypeValues}
                      </Text>
                    </Tooltip>
                  </Select.Option>
                );
              }
            )}
          </SelectDropdown>
        );
      default:
        return <></>;
    }
  };

  const intl = useIntl();
  const [pickerSelected, setPickerOpenState] = useState(undefined);
  const { isMobile } = useMobileContext();
  const [endOpen, setEndOpen] = useState<boolean>(false);
  const [startOpen, setStartOpen] = useState<boolean>(false);

  //set another ref for datepickers
  const modalContainerRef = useRef<HTMLDivElement>(null);

  const formsWithFiveFields = [
    EventsTypes.Vacation,
    EventsTypes.WorkFromHome,
    EventsTypes.Unpaid,
    EventsTypes.ForgotBadge
  ];

  return (
    <ComponentWithStatus
      status={status}
      Placeholder={
        <TORFormPlaceholder
          heightProps={
            !formsWithFiveFields.includes(timeOffRequestType as any) &&
            requestType !== 'special'
              ? '334px'
              : '286px'
          }
        />
      }
    >
      <div ref={modalContainerRef}>
        <Form
          form={form}
          id="timeOffForm"
          key="timeOffFormKey"
          labelCol={{ span: 6 }}
          labelAlign="left"
          layout="horizontal"
          labelWrap
          colon={false}
          onFinish={formData => {
            const dates =
              form.getFieldValue('dates') &&
              form.getFieldValue('dates').length > 1
                ? form.getFieldValue('dates')
                : [];
            prepareAdditionalFields(formData, additionalFieldsSchema);
            const [calculatedStartDate, calculatedEndDate] =
              calculateStartEndDates({
                startDate,
                endDate,
                dates,
                isTimeBasedRequest: isTimeBasedRequest(timeOffRequestType),
                formData
              });

            onSubmitTimeOffRequestForm({
              requestType: timeOffRequestType,
              scope,
              formData,
              timeOffId,
              actions: onFinishFormActions,
              startDate: calculatedStartDate,
              endDate: calculatedEndDate,
              fileList,
              status: !isRegularFormSubmit ? 'Unfinished' : requestStatus,
              employeeId: employeeId,
              isRegularFormSubmit,
              filesToRemove
            });
          }}
          onFinishFailed={validationError =>
            onFormFinishFailedValidation(validationError, dispatch)
          }
          onSubmitCapture={(
            event: React.SyntheticEvent<HTMLFormElement, SubmitEvent>
          ) => {
            isRegularFormSubmit =
              event?.nativeEvent?.submitter?.id === 'submitButton';
          }}
        >
          {/* FIELD: Colleague */}

          {requestType === 'colleagues' && (
            <ColFormItemComponent
              order={10}
              span={24}
              label={<FormattedMessage id="Colleague" />}
              name="requestedForId"
              rules={[
                {
                  required: true,
                  validator(_, value) {
                    return isRegularFormSubmit && !value
                      ? Promise.reject(
                          new Error(
                            intl.formatMessage({
                              id: 'RequestForAColleagueValidation'
                            })
                          )
                        )
                      : Promise.resolve();
                  }
                }
              ]}
            >
              <SelectDropdown
                placement_bottom={true}
                disabled={scope === 'draft'}
                onChange={e => {
                  TimeOffService.getTimeOffStatisticsByEmployeeId(e).then(
                    res => {
                      if (!data) {
                        //status !== 'success'
                        return;
                      }

                      setStatisticData(
                        (res.data as TimeOffStatisticsResultData).statistics
                      );
                      setSelectedColleagueProfile(
                        EmployeesMap.get(form.getFieldValue('requestedForId'))
                      );

                      updateDaysAllowed(
                        // form.getFieldValue('requestType'),
                        timeOffRequestType,
                        (res.data as TimeOffStatisticsResultData).statistics,
                        requestStatus,
                        setDaysAllowed
                      );
                    }
                  );
                }}
              >
                {getListOfEmployees(rootSelector).map(
                  ({ id, name, officialFunction }: any) => (
                    <Select.Option key={`employee-${id}`} value={id}>
                      {`${name}, ${officialFunction}`}
                    </Select.Option>
                  )
                )}
              </SelectDropdown>
            </ColFormItemComponent>
          )}

          {/* FIELD: Type */}

          <ColFormItemComponent
            order={20}
            span={24}
            label={<FormattedMessage id="Type" />}
            name="requestType"
            rules={[
              {
                validator(_, value) {
                  return isRegularFormSubmit && !value
                    ? Promise.reject(
                        new Error(
                          intl.formatMessage({
                            id: 'RequestForAColleagueTypeValidation'
                          })
                        )
                      )
                    : Promise.resolve();
                }
              }
            ]}
            // isDisabled={scope === 'view' ? true : requestTimeOffType ? true : false}
            isReadOnly={
              requestType !== 'colleagues'
                ? `col-form-item-component-view ${theme}`
                : ''
            }
          >
            {requestType === 'colleagues' ? (
              <SelectDropdown
                placement_bottom={true}
                customfilter
                disabled={
                  ['draft', 'view'].includes(scope) ||
                  requestStatus === 'Unfinished' ||
                  !!requestTimeOffType
                }
                onChange={e => {
                  setSelectedColleaguesPolicy(e);
                  form.setFieldValue(
                    'dates',
                    isTimeBasedRequest(e) ? dayjs() : [dayjs(), null]
                  );

                  if (!data || !data.statistics || !data.statistics.length) {
                    return;
                  }

                  updateDaysAllowed(
                    e,
                    requestType === 'colleagues'
                      ? statisticData
                      : data.statistics,
                    requestStatus,
                    setDaysAllowed
                  );
                  setTimeOffRequestType(e);
                }}
              >
                {getListOfTimeOffPolicies(rootSelector.general).map(policy => {
                  const typeOfPolicies = getTitleSelects(
                    policy,
                    'timeOffPolicies'
                  );
                  return (
                    <Select.Option
                      key={`policy-${policy}`}
                      value={policy}
                      label={typeOfPolicies}
                    >
                      {typeOfPolicies}
                    </Select.Option>
                  );
                })}
              </SelectDropdown>
            ) : requestType === 'special' ? (
              <SelectDropdown
                placement_bottom={true}
                customfilter
                disabled={
                  ['draft', 'view'].includes(scope) ||
                  requestStatus === 'Unfinished' ||
                  !!requestTimeOffType
                }
                onChange={e => {
                  setSelectedColleaguesPolicy(e);
                  form.setFieldValue(
                    'dates',
                    isTimeBasedRequest(e) ? dayjs() : [dayjs(), null]
                  );

                  if (!data || !data.statistics || !data.statistics.length) {
                    return;
                  }

                  updateDaysAllowed(
                    e,
                    data.statistics,
                    requestStatus,
                    setDaysAllowed
                  );
                  setTimeOffRequestType(e);
                }}
              >
                {specialRequests.map(policy => {
                  const typeOfPolicies = getTitleSelects(
                    policy,
                    'timeOffPolicies'
                  );
                  return (
                    <Select.Option
                      key={`policy-${policy}`}
                      value={policy}
                      label={typeOfPolicies}
                    >
                      {typeOfPolicies}
                    </Select.Option>
                  );
                })}
              </SelectDropdown>
            ) : (
              <Input disabled />
            )}
          </ColFormItemComponent>

          {/* FIELD: Allowed Days */}

          {scope !== 'draft' && (
            <ColFormItemComponent
              order={40}
              span={24}
              label={<FormattedMessage id="AllowedDays" />}
              name="daysAllowed"
              isReadOnly={true && `col-form-item-component-view ${theme}`}
            >
              <Input disabled />
            </ColFormItemComponent>
          )}

          {/* FIELD: Date / Dates */}

          <ColFormItemComponent
            order={50}
            span={24}
            label={
              isTimeBasedRequest(timeOffRequestType) ? (
                <FormattedMessage id="Date" />
              ) : (
                <FormattedMessage id="Dates" />
              )
            }
            name="dates"
            rules={
              !isAdminRole() || timeOffRequestType !== 'WorkFromHome'
                ? [
                    {
                      validator(_, value) {
                        return isTimeBasedRequest(timeOffRequestType) ||
                          isSingleDayRequestPolicy(
                            requestTimeOffType || selectedColleaguesPolicy
                          )
                          ? validateSingleDate(value, intl, daysAllowed)
                          : validateDates(
                              value,
                              requestStatus,
                              daysAllowed,
                              timeOffRequestType,
                              isRegularFormSubmit,
                              intl,
                              daysAllowedType,
                              rootSelector
                            );
                      }
                    }
                  ]
                : []
            }
          >
            {isTimeBasedRequest(timeOffRequestType) ? (
              <DatePicker
                inputReadOnly={true}
                style={{ width: '100%' }}
                getPopupContainer={container => {
                  return isMobile
                    ? container
                    : (modalContainerRef.current as HTMLElement);
                }}
                popupClassName={theme}
                format="DD/MM/YYYY"
                disabledDate={current => {
                  if (
                    ['LeaveOffsetting'].includes(requestTypeOff) &&
                    !isAdminRole() &&
                    forbidOffsetForFutureHours
                  ) {
                    return (
                      current &&
                      (current < dayjs().startOf('day') ||
                        current > dayjs().endOf('day'))
                    );
                  }

                  return allowedDateSelectionOnTimeBasedRequest(
                    current,
                    getCurrentUserRole(user),
                    countryCode,
                    scope,
                    selectedColleagueProfile,
                    user
                  );
                }}
                onChange={e => {
                  const currentDate = dayjs(e);
                  setStartDate(currentDate);
                  setEndDate(currentDate);
                }}
              />
            ) : !isMobile ? (
              isSingleDayRequestPolicy(
                requestType === 'colleagues' || requestType === 'special'
                  ? selectedColleaguesPolicy
                  : requestTimeOffType
              ) ? (
                <>
                  <DatePicker
                    style={{ width: '100%', marginBottom: '16px' }}
                    getPopupContainer={() => {
                      return modalContainerRef.current as HTMLElement;
                    }}
                    popupClassName={theme}
                    format="DD/MM/YYYY"
                    inputReadOnly={true}
                    defaultValue={startDate}
                    allowClear={allowedDatePickerPopupSelection}
                    disabledDate={current => {
                      return allowedDateRangePickerSelection(
                        current,
                        isStartDateSelected,
                        countryCode,
                        requestType === 'personal'
                          ? requestTypeOff
                          : selectedColleaguesPolicy,
                        requestType === 'personal'
                          ? employeeProfile?.workingSchedule
                          : selectedColleagueProfile?.workingSchedule,
                        getCurrentUserRole(user),
                        scope,
                        selectedColleagueProfile,
                        user
                      );
                    }}
                    placeholder={intl.formatMessage({ id: 'startDate' })}
                    onChange={e => {
                      const currentDate = dayjs(e);
                      setStartDate(currentDate);
                      updateDatesValues(
                        [currentDate, currentDate],
                        form,
                        setStartDate,
                        setEndDate,
                        setDaysRequested,
                        daysAllowedType,
                        rootSelector
                      );
                    }}
                    open={startOpen}
                    onOpenChange={e => {
                      allowedDatePickerPopupSelection && setStartOpen(e);
                    }}
                  />
                </>
              ) : (
                <DateRangePicker
                  inputReadOnly={true}
                  disabledKeyboard
                  style={{ width: '100%' }}
                  getPopupContainer={() => {
                    return modalContainerRef.current as HTMLElement;
                  }}
                  format="DD/MM/YYYY"
                  open={pickerSelected}
                  disabledDate={current => {
                    return allowedDateRangePickerSelection(
                      current,
                      isStartDateSelected,
                      countryCode,
                      requestType === 'personal'
                        ? requestTypeOff
                        : selectedColleaguesPolicy,
                      requestType === 'personal'
                        ? employeeProfile?.workingSchedule
                        : selectedColleagueProfile?.workingSchedule,
                      getCurrentUserRole(user),
                      scope,
                      selectedColleagueProfile,
                      user
                    );
                  }}
                  onOpenChange={e => {
                    if (e === false) {
                      form.validateFields(['dates']);
                    }
                    setPickerOpenState(e);
                  }}
                  onStartDateSelected={() => setIsStartDateSelected(true)}
                  onEndDateSelected={() => setIsStartDateSelected(false)}
                  onCalendarChange={e => {
                    // if startDate is blank or invalid or endDate is empty, keep datepicker open
                    if (
                      !e[0] ||
                      (e[0] && !isValidTimeOffDateSelected(e[0])) ||
                      !e[1]
                    ) {
                      setPickerOpenState(true);
                      return;
                    }

                    if (e && e[1]) {
                      setPickerOpenState(false);

                      // Create a Promise to Focus on Description Text Area
                      // After the Date was Selected.
                      if (additionalFieldsSchema.length !== 0) {
                        setTimeout(() => {
                          additionalInputRef?.current?.focus();
                        }, 10);
                      } else {
                        setTimeout(() => {
                          descriptionInputRef?.current?.focus();
                        }, 10);
                      }
                    }

                    updateDatesValues(
                      e,
                      form,
                      setStartDate,
                      setEndDate,
                      setDaysRequested,
                      daysAllowedType,
                      rootSelector
                    );
                  }}
                />
              )
            ) : isSingleDayRequestPolicy(
                requestType === 'colleagues'
                  ? selectedColleaguesPolicy
                  : requestTimeOffType
              ) ? (
              <>
                <DatePicker
                  popupClassName={theme}
                  style={{ width: '100%' }}
                  format="DD/MM/YYYY"
                  inputReadOnly={true}
                  defaultValue={startDate}
                  allowClear={allowedDatePickerPopupSelection}
                  disabledDate={current => {
                    return allowedDateRangePickerSelection(
                      current,
                      isStartDateSelected,
                      countryCode,
                      requestType === 'personal'
                        ? requestTypeOff
                        : selectedColleaguesPolicy,
                      requestType === 'personal'
                        ? employeeProfile?.workingSchedule
                        : selectedColleagueProfile?.workingSchedule,
                      getCurrentUserRole(user),
                      scope,
                      selectedColleagueProfile,
                      user
                    );
                  }}
                  placeholder={intl.formatMessage({ id: 'startDate' })}
                  onChange={e => {
                    const currentDate = dayjs(e);
                    setStartDate(currentDate);
                    updateDatesValues(
                      [currentDate, currentDate],
                      form,
                      setStartDate,
                      setEndDate,
                      setDaysRequested,
                      daysAllowedType,
                      rootSelector
                    );
                  }}
                  open={startOpen}
                  onOpenChange={e => {
                    allowedDatePickerPopupSelection && setStartOpen(e);
                  }}
                />
              </>
            ) : (
              <>
                <DatePicker
                  popupClassName={theme}
                  style={{ width: '100%', marginBottom: '16px' }}
                  format="DD/MM/YYYY"
                  inputReadOnly={true}
                  disabledDate={current => {
                    return allowedDateRangePickerSelection(
                      current,
                      isStartDateSelected,
                      countryCode,
                      requestType === 'personal'
                        ? requestTypeOff
                        : selectedColleaguesPolicy,
                      requestType === 'personal'
                        ? employeeProfile?.workingSchedule
                        : selectedColleagueProfile?.workingSchedule,
                      getCurrentUserRole(user),
                      scope,
                      selectedColleagueProfile,
                      user
                    );
                  }}
                  value={startDate ?? dayjs().startOf('day')}
                  placeholder={intl.formatMessage({ id: 'startDate' })}
                  onChange={e => {
                    if (!e) return null;
                    const currentDate = dayjs(e);
                    setStartDate(currentDate);
                    setEndOpen(true);
                    if (endDate && e) {
                      setEndOpen(false);
                      updateDatesValues(
                        [e, endDate],
                        form,
                        setStartDate,
                        setEndDate,
                        setDaysRequested,
                        daysAllowedType,
                        rootSelector
                      );
                    }
                  }}
                  open={startOpen}
                  onOpenChange={e => {
                    if (endDate && e === false) {
                      form.validateFields(['dates']);
                    }
                    setStartOpen(e);
                  }}
                />
                <DatePicker
                  popupClassName={theme}
                  style={{ width: '100%' }}
                  format="DD/MM/YYYY"
                  inputReadOnly={true}
                  disabledDate={current => {
                    return (
                      startDate && current && current < startDate.startOf('day')
                    );
                  }}
                  value={endDate}
                  placeholder={intl.formatMessage({ id: 'endDate' })}
                  onOpenChange={e => {
                    if (e === false) {
                      form.validateFields(['dates']);
                    }
                    setEndOpen(e);
                  }}
                  onChange={e => {
                    if (!e) return null;
                    const currentDate = dayjs(e);
                    setEndDate(currentDate);
                    setEndOpen(false);
                    if (
                      !startDate ||
                      (startDate && !isValidTimeOffDateSelected(startDate)) ||
                      !e
                    ) {
                      setEndOpen(true);
                      return;
                    }
                    if (e && startDate) {
                      setEndOpen(false);
                      updateDatesValues(
                        [startDate, e],
                        form,
                        setStartDate,
                        setEndDate,
                        setDaysRequested,
                        daysAllowedType,
                        rootSelector
                      );
                    }
                  }}
                  open={endOpen}
                />
              </>
            )}
          </ColFormItemComponent>

          {/* FIELD: Requested */}

          <ColFormItemComponent
            order={60}
            span={24}
            label={<FormattedMessage id="Requested" />}
            isReadOnly={true && `col-form-item-component-view ${theme}`}
            name="daysRequested"
            rules={[
              {
                validator(_, value) {
                  return isRegularFormSubmit && (value === '0' || value === 0)
                    ? Promise.reject(
                        new Error(
                          intl.formatMessage({
                            id: 'RequestOnHolidayError'
                          })
                        )
                      )
                    : Promise.resolve();
                }
              }
            ]}
          >
            <Input disabled />
          </ColFormItemComponent>

          {/* SECTION: Additional Fields */}

          {additionalFieldsSchema &&
            additionalFieldsSchema.map(
              (field: AdditionalFieldSchema, index) => (
                <ColFormItemComponent
                  order={field.order ?? index + 60}
                  label={<FormattedMessage id={field.name} />}
                  name={field.name}
                  key={index}
                  className={`col-form-item-component ${theme}`}
                  rules={[
                    {
                      required: field.isRequired,
                      message: (
                        <FormattedMessage
                          id="provideField"
                          values={{
                            fieldName: <FormattedMessage id={field.name} />
                          }}
                        />
                      ),
                      validator(_, value) {
                        return !isRegularFormSubmit
                          ? Promise.resolve()
                          : validateAdditionalFields(value, field, intl); //ignore validation for unfinished requests
                      }
                    },
                    {
                      validator(_, value) {
                        const validationErrorMessage = intl.formatMessage({
                          id: 'ValidationTimeRangeEndTimeLessStartTime'
                        });

                        if (
                          isInvalidRequestType &&
                          value !== undefined &&
                          value !== null &&
                          value[0] !== null &&
                          value[1] !== null &&
                          value[0].hour() >= value[1].hour() &&
                          value[0].minute() >= value[1].minute()
                        ) {
                          return Promise.reject(validationErrorMessage);
                        } else {
                          return Promise.resolve();
                        }
                      }
                    },
                    {
                      validator(_, value) {
                        const validationErrorMessage = intl.formatMessage({
                          id: 'ValidationTimeRangeEndTimeInvalid'
                        });
                        if (
                          isInvalidRequestType &&
                          value !== undefined &&
                          value !== null &&
                          value[1] !== null &&
                          dayjs(value[1]).format('HH:mm') === '00:00'
                        ) {
                          return Promise.reject(validationErrorMessage);
                        } else {
                          return Promise.resolve();
                        }
                      }
                    }
                  ]}
                >
                  {renderSwitch(field, index)}
                </ColFormItemComponent>
              )
            )}

          {/* FIELD: Notes */}

          <ColFormItemComponent
            order={70}
            span={24}
            label={<FormattedMessage id="Notes" />}
            name="notes"
          >
            <TextArea ref={descriptionInputRef} maxLength={500} />
          </ColFormItemComponent>

          {/* RADIO: Draft */}

          {requestStatus !== 'Unfinished' && (
            <ColFormItemComponent
              order={requestType === 'colleagues' ? 80 : 80}
              wrapperCol={{ span: 12 }}
              span={24}
              label={<FormattedMessage id="label_time_off_form" />}
              name="draft"
              valuePropName="checked"
              className="form_item_draft"
            >
              <Checkbox
                onChange={e => {
                  changeModalSubmitBtnText(e.target.checked, intl);
                  updateDaysAllowed(
                    timeOffRequestType,
                    data.statistics,
                    e.target.checked ? 'Draft' : 'Pending',
                    setDaysAllowed
                  );

                  isRequestEditable(timeOffRequestType) &&
                    setIsFileRequired(e.target.checked);

                  setRequestStatus(e.target.checked ? 'Draft' : 'Pending');
                }}
              />
            </ColFormItemComponent>
          )}

          {/* FIELD: Upload Files */}

          {isUploadingEnabled(timeOffRequestType, countryCode) && (
            <>
              <Row justify="start" style={{ order: 999 }}>
                <ColFormItemComponent
                  span={24}
                  name="uploadFile"
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                    flexDirection: 'row-reverse',
                    textAlign: 'left'
                  }}
                  wrapperCol={{ span: 24 }}
                  rules={[
                    {
                      validator(_, value) {
                        if (isRegularFormSubmit) {
                          if (
                            isFileRequired &&
                            (!value || value?.length === 0)
                          ) {
                            return Promise.reject(
                              new Error(
                                intl.formatMessage({
                                  id: 'CertificateInputValidation'
                                })
                              )
                            );
                          }
                        }
                        return Promise.resolve();
                      }
                    }
                  ]}
                >
                  <Upload
                    multiple={true}
                    fileList={fileList}
                    showUploadList={{
                      showDownloadIcon: true,
                      showRemoveIcon: true
                    }}
                    itemRender={(originNode, file) => {
                      // Start work 🚀
                      let binaryData = [];
                      binaryData.push(file);

                      return (
                        <div>
                          {/* 
                      TODO: Add Custom Delete Button. Wait for design. 
                      BOOKMARK 🚀
                    */}

                          <div className={`label-attachement ${theme} `}>
                            <div className="attachemt_text">
                              <FormattedMessage id="attachment" />
                            </div>
                            <div className="attachemnt_container-btns">
                              <div className="btns-row">
                                <a
                                  href={URL.createObjectURL(
                                    new Blob(binaryData)
                                  )}
                                  download={file.name}
                                  rel="noopener noreferrer"
                                  target={'_blank'}
                                  className={`btn_download ${theme}`}
                                >
                                  <TimeOffDownloadIcon />
                                </a>
                                <>
                                  <div className="separator"></div>
                                  <ButtonCustom
                                    className={`text-bold-normal btn-default-custom  ${theme}`}
                                    type="button"
                                    onClick={() => onRemoveFile(file)}
                                  >
                                    <TimeOffDeleteIcon />
                                  </ButtonCustom>
                                </>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }}
                    accept={acceptFilesExtension}
                    maxCount={maxFilesPerRequest}
                    beforeUpload={uploadedFile => {
                      const isAllowedType = validateFileType(
                        uploadedFile,
                        acceptFilesExtension
                      );
                      if (!isAllowedType) {
                        notifyWithError({
                          message: (
                            <FormattedMessage id="upload_file_type_not_allowed" />
                          ),
                          description: null
                        });
                        form.validateFields(['uploadFile']);
                        return false;
                      }
                      if (
                        fileList &&
                        fileList.length > maxFilesPerRequest - 1
                      ) {
                        notifyWithError({
                          message: (
                            <FormattedMessage
                              id="MaximumFilesToUploadErrorMessage"
                              values={{
                                maxFilesPerRequest: maxFilesPerRequest
                              }}
                            />
                          ),
                          description: null
                        });
                        form.validateFields(['uploadFile']);
                        return false;
                      }

                      if (
                        uploadedFile.size &&
                        uploadedFile.size >= 10 * 1000000
                      ) {
                        notifyWithErrorOnMaxSizeUploadFile(10);
                        form.validateFields(['uploadFile']);
                        return false;
                      }

                      setFileList([...fileList, uploadedFile]);
                      form.setFieldsValue({
                        uploadFile: [...fileList, uploadedFile]
                      });
                      return false;
                    }}
                  >
                    <ButtonCustom
                      className={`text-bold-normal btn-upload-custom  ${theme}`}
                      style={{
                        padding: '4px 8px'
                      }}
                    >
                      <span>
                        <FormattedMessage id="Upload" />
                      </span>
                    </ButtonCustom>
                  </Upload>
                </ColFormItemComponent>
              </Row>
            </>
          )}
        </Form>
      </div>
    </ComponentWithStatus>
  );
};

export default TimeOffRequestForm;
