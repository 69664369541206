import { FileExclamationOutlined, WarningOutlined, CheckOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ButtonSimple } from 'components/Buttons';
import ButtonWithExpandedText from 'components/Buttons/ButtonWithExpandedText';
import { convertToLocalDate } from 'components/DateParser';
import { getColumnSearchProps } from './SearchTable';
import ThemedIcon from 'components/UIComponents/Icon';
import { TimeOffDownloadIcon } from 'Icons/TimeOffActionIcons/TimeOffDownloadIcon';
import { TimeOffEditIcon } from 'Icons/TimeOffActionIcons/TimeOffEditIcon';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { TimeOffService } from 'services';
import { download_files } from 'utils/downloadFiles';
import isValidUrl from 'utils/isValidUrl';
import { notifyWithError } from 'utils/notificationsUtils';
import { isUploadingEnabled } from '../../TimeOffRequestForm/utils';
import {
  getRequestTimeOffActionStatusDetails,
  TimeOffRequestActionsConfirmButtons,
  TimeOffRequestActionsDenyButtons,
  TimeOffRequestActionsEmployeeDenyButtons
} from '../RequestActionButton';
import {
  computeHoursAndMinutes,
  downloadTimeOffRequestTemplate
} from '../utils';
import {
  isDownloadableRequestType,
  policyHasOrder,
  shallDisplaySignedDocumentsToolTip,
  showDownloadRequestButtonStatuses
} from './utils';
import {
  adminRoles,
  allowPermision,
  userRoles
} from '../../../../../utils/userManagement';
import { formatedData, formatedTime } from "../../../../../utils/formatDateTime";

export const timeOffRequestTableColumns = (
  data,
  role,
  params,
  isModalOpen,
  setIsModalOpen,
  stateActions,
  dataSource,
  modalOpenValues,
  teamsFilter,
  nextApproverFilter,
  setModalOpenValues,
  isTableDevice,
  isSmallLaptop,
  intl,
  countryCode
): ColumnsType<any> => {
  const isAdminUserRole = allowPermision(role, adminRoles);

  const tableColumns = [
    {
      title: <FormattedMessage id="table_ColleaguesRequestsCol_Requester" />,
      dataIndex: 'requestedForEmployee',
      width: 'clamp(50px, 10vw, 10vw)',
      filterSearch: true,
      ...getColumnSearchProps(),
      render: (_, record) => (
        <div className="line-clamp-1">
          <Tooltip
            placement="top"
            title={`${record.requestedForEmployee?.lastName} ${record.requestedForEmployee?.firstName}`}
          >
            {allowPermision(role, [userRoles.Admin, userRoles.HR]) ? (
              <Link
                to={`/allUsersList/viewProfile?employeeId=${record.requestedForEmployee.employeeId}`}
              >
                {`${record.requestedForEmployee?.lastName} ${record.requestedForEmployee?.firstName}`}
              </Link>
            ) : (
              <p>
                {`${record.requestedForEmployee?.lastName} ${record.requestedForEmployee?.firstName}`}
              </p>
            )}
          </Tooltip>
        </div>
      )
    },
    {
      title: <FormattedMessage id="teams" />,
      dataIndex: 'teams',
      width: isSmallLaptop ? '80px' : 'clamp(50px, 10vw, 10vw)',
      responsive: ['xxl'] as any,
      filters: teamsFilter,
      render: (_, record) =>
        record?.requestedForEmployee?.teams ? (
          <Tooltip
            placement="top"
            title={Object.values(record.requestedForEmployee.teams).join(', ')}
          >
            <div className="line-clamp-1">
              {Object.entries(record.requestedForEmployee.teams).map(
                ([teamId, teamName], index) => (
                  <span key={teamId}>
                    {index > 0 ? ', ' + teamName : teamName.toString()}
                  </span>
                )
              )}
            </div>
          </Tooltip>
        ) : (
          <p>-</p>
        )
    },
    {
      title: <FormattedMessage id="table_ColleaguesRequestsCol_Type" />,
      dataIndex: 'publicName',
      width: '160px',
      render: (_, record) => (
        <Tooltip title={<FormattedMessage id={record.publicName} />}>
          <p className={'line-clamp-1'}>
            <FormattedMessage id={record.publicName} />
          </p>
        </Tooltip>
      )
    },
    {
      title: <FormattedMessage id="table_ColleaguesRequestsCol_Amount" />,
      dataIndex: 'daysTaken',
      ellipsis: {
        showTitle: false
      },
      width: '170px',
      render: (_, record) => {
        let hoursResults;
        const requestTypes = record.requestType;
        const hourlyBasedTimeOffRequestType = [
          'PersonalLeave',
          'Overtime',
          'LeaveOffsetting'
        ].includes(requestTypes);
        if (hourlyBasedTimeOffRequestType) {
          const parseStartDate = convertToLocalDate(record.startDate).second(0);
          const parseEndDate = convertToLocalDate(record.endDate).second(0);

          const clockInOutDifference = Math.round(
            parseEndDate.diff(parseStartDate, 'minute', true)
          );

          const clockInOutHoursSpentDiff = parseEndDate.diff(
            parseStartDate,
            'hours'
          );

          const clockInOutRemainedMinutesDiff = clockInOutDifference % 60;
          hoursResults = !record.endDate ? (
            <FormattedMessage id="OngoingRequestText" />
          ) : (
            clockInOutHoursSpentDiff +
            'h ' +
            clockInOutRemainedMinutesDiff +
            'm, '
          );
        } else {
          hoursResults =
            record.daysTaken === 0 ? (
              <FormattedMessage id="OngoingRequestText" />
            ) : (
              <p>
                {record.daysTaken}{' '}
                {record.daysTaken === 1 ? (
                  <FormattedMessage id="day" />
                ) : (
                  <FormattedMessage id="days" />
                )}
              </p>
            );
        }
        return (
          <div className="line-clamp-1">
            {hoursResults}
            {hourlyBasedTimeOffRequestType && (
              <span>
                {convertToLocalDate(record.startDate).format('HH')}
                <span className={'minutes'}>
                  {convertToLocalDate(record.startDate).format('mm')}
                </span>{' '}
                - {convertToLocalDate(record.endDate).format('HH')}
                <span className={'minutes'}>
                  {convertToLocalDate(record.endDate).format('mm')}
                </span>
              </span>
            )}
          </div>
        );
      }
    },
    {
      title: <FormattedMessage id="table_ColleaguesRequestsCol_From" />,
      dataIndex: 'startDate',
      width: '100px',
      render: (_, record) => (
        <p>{convertToLocalDate(record.startDate).format('DD/MM/YYYY')}</p>
      )
    },
    {
      title: <FormattedMessage id="table_ColleaguesRequestsCol_Until" />,
      dataIndex: 'endDate',
      width: '100px',
      render: (_, record) => (
        <p>
          {record.endDate ? (
            convertToLocalDate(record.endDate).format('DD/MM/YYYY')
          ) : (
            <FormattedMessage id="OngoingRequestText" />
          )}
        </p>
      )
    },
    {
      title: <FormattedMessage id="table_ColleaguesRequestsCol_Status" />,
      dataIndex: 'status',
      width: isSmallLaptop ? '5em' : '9em',
      render: (_, record) => (
        <p
          className={''}
          style={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
          }}
        >
          <Tooltip
            placement="top"
            title={<FormattedMessage id={record.status} />}
          >
            <span>
              {' '}
              <FormattedMessage id={record.status} />
            </span>
          </Tooltip>
          {shallDisplaySignedDocumentsToolTip(
            record.status,
            record.isSigned,
            countryCode
          ) ? (
            <Tooltip title={<FormattedMessage id="AwaitingSignedDocuments" />}>
              {' '}
              <FileExclamationOutlined />
            </Tooltip>
          ) : null}
        </p>
      )
    },
    {
      title: (
        <FormattedMessage id="table_ColleaguesRequestsCol_Next_Approver" />
      ),
      dataIndex: 'primaryApproverEmployee',
      width: 'clamp(50px, 10vw, 10vw)',
      className: 'hoverEdit',
      filters: nextApproverFilter,
      render: (_, record) => {
        const statusDetails = getRequestTimeOffActionStatusDetails({ record });
        return (
          <Tooltip title={statusDetails}>
            <div
              style={{
                display: 'flex'
              }}
            >
              <span className="line-clamp-1">{statusDetails}</span>
            </div>
          </Tooltip>
        );
      }
    },
    {
      title: <FormattedMessage id="table_ColleaguesRequestsCol_SubmittedOn" />,
      dataIndex: 'requestedDate',
      width: '100px',
      responsive: ['xl'] as any,
      className: 'hoverEdit',
      render: (_, record) => (
        <p>
          {convertToLocalDate(
            record.requestedDate && new Date(record.requestedDate) > new Date(0)
              ? record.requestedDate
              : record.createdDate
          ).format('DD/MM/YYYY')}
        </p>
      )
    },
    {
      title: <FormattedMessage id="table_ColleaguesRequestsCol_Notes" />,
      dataIndex: 'notes',
      width: isTableDevice ? '10em' : isSmallLaptop ? '10em' : 'auto',
      className: 'hoverEdit',
      // ellipsis: true,
      render: (_, record) => {
        const showHoverButtons = {
          showDenyButton: false,
          showCancelButton: false,
          showDenyRevokeButton: false,
          showRevokeButton: false,
          showSubmitButton: false,
          showApproveButton: false,
          showSignButton: false,
          showConfirmRevokeButton: false,
          showConfirmButton: false,
          showApproveAndConfirmeButton: false
        };

        const requestType = record.requestType;
        const isStatusApprovedOrConfirmedOrApprovedSecondary =
          record.status === 'Approved' ||
          record.status === 'Confirmed' ||
          record.status === 'ApprovedSecondary';

        const primaryApproverEmployeeEmail =
          record.primaryApproverEmployee?.email?.toLowerCase();

        const requestParameters = {
          requestId: record.requestId,
          employeeId: record.requestedForEmployee?.employeeId
        };

        const currentUserLoggedEmail = data.user?.email?.toLowerCase();
        const requestedForEmail =
          record.requestedForEmployee?.email?.toLowerCase();
        const requestedByEmployeeEmail =
          record?.requestedByEmployeeEmail?.toLowerCase();

        const isAdminOrHasRequiredStatuses =
          isAdminUserRole ||
          (currentUserLoggedEmail === requestedForEmail &&
            showDownloadRequestButtonStatuses[countryCode]?.includes(
              record.status
            ));

        const certificateCheck =
          isUploadingEnabled(record.requestType, countryCode) &&
          (isAdminUserRole || currentUserLoggedEmail === requestedForEmail) &&
          record.attachmentUrls;

        const requestCheck =
          isDownloadableRequestType(requestType, countryCode) &&
          isAdminOrHasRequiredStatuses;
        const orderCheck =
          data?.requestType === 'colleagues' &&
          currentUserLoggedEmail === primaryApproverEmployeeEmail &&
          isStatusApprovedOrConfirmedOrApprovedSecondary &&
          countryCode !== 'RO' &&
          policyHasOrder.includes(requestType);

        //Add logic for download button
        const handleCertificateDownload = (
          $event: React.MouseEvent<HTMLButtonElement>
        ) => {
          $event.preventDefault();
          Promise.all(
            record.attachmentUrls
              .split('©')
              .map(async (link: string, index: number): Promise<any> => {
                //attachmentURL contains links from local FileStorage e.g https://intranet-testing.jivygroup.com/FileStorage/Name_surname/myFile.png
                if (isValidUrl(link)) {
                  return {
                    link: link,
                    fileName: link.split('/').at(-1)
                  };
                } else {
                  //attachmentURL contains path from azure e.g /Employee/File.png
                  const response = await TimeOffService.getRequestAttachmentUrl(
                    {
                      requestId: record.requestId,
                      fileOrder: index
                    }
                  );

                  if (!response)
                    throw new Error(
                      `Couldn't get files for recordId ${record.requestId}`
                    );

                  return {
                    link: response.data,
                    fileName: (response.data as string)
                      .split('/')
                      .at(-1)
                      .split('?sv=')[0]
                  };
                }
              })
          )
            .then(data => {
              download_files(data);
            })
            .catch(e => {
              notifyWithError({
                message: <FormattedMessage id="FileDownloadErrorTitle" />,
                description: <FormattedMessage id="FileDownloadError" />
              }); // in the future we can add some exception text as parameter when error handling will be implemented
              console.error($event);
            });
        };

        const handleRequestDownload = () => {
          downloadTimeOffRequestTemplate(
            TimeOffService.getTimeOffRequestFile(requestParameters)
          );
        };

        const handleOrderDownload = () => {
          downloadTimeOffRequestTemplate(
            TimeOffService.getTimeOffDecisionFile(requestParameters)
          );
        };

        const numberOfAvailableDownloads = [
          certificateCheck,
          requestCheck,
          orderCheck
        ].filter(Boolean).length;

        const availableDownloads = {
          certificate: {
            check: certificateCheck,
            textId: 'downloadCertificate',
            action: handleCertificateDownload
          },
          request: {
            check: requestCheck,
            textId: 'downloadRequest',
            action: handleRequestDownload
          },
          order: {
            check: orderCheck,
            textId: 'downloadOrder',
            action: handleOrderDownload
          }
        };

        return (
          <div className={'notes-container'}>
            <Tooltip title={record?.notes}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <span className="line-clamp-1">{record?.notes}</span>
              </div>
            </Tooltip>
            <div className="showEdit" style={{ display: 'none' }}>
              <div className="actionButtons">
                {(isAdminUserRole ||
                  currentUserLoggedEmail === requestedForEmail ||
                  currentUserLoggedEmail === requestedByEmployeeEmail) &&
                ['Unfinished'].includes(record?.status) ? (
                  <ButtonWithExpandedText
                    displayedText={<FormattedMessage id="edit" />}
                    embedSrc={<ThemedIcon icon={TimeOffEditIcon} />}
                    type="link"
                    onClick={() => {
                      if (
                        stateActions?.setModalOpenValues &&
                        typeof stateActions?.setModalOpenValues === 'function'
                      ) {
                        stateActions?.setModalOpenValues({
                          ...modalOpenValues,
                          request: false,
                          draft: true
                        });
                      }
                      stateActions?.toggleModal();
                      stateActions?.setModalProps({
                        requestType: record.requestType ?? 'personal',
                        scope:
                          record.status === 'Draft'
                            ? 'draft'
                            : isAdminUserRole
                              ? 'view'
                              : 'request',
                        timeOffId: record.requestId,
                        displayRequestButton: true,
                        formKeyId: 'timeOffForm',
                        modalTitle: (
                          <FormattedMessage id="RequestTimeOffModalTitle" />
                        ),
                        submitBtnText: <FormattedMessage id="save" />
                      });
                    }}
                  />
                ) : null}
                <TimeOffRequestActionsConfirmButtons
                  record={record}
                  setIsModalOpen={setIsModalOpen}
                  showHoverButtons={showHoverButtons}
                  stateActions={stateActions}
                  userEmail={currentUserLoggedEmail}
                  isUserAdmin={isAdminUserRole}
                  modalOpenValues={modalOpenValues}
                  setModalOpenValues={stateActions?.setModalOpenValues}
                />
                <TimeOffRequestActionsDenyButtons
                  record={record}
                  tableRequestType={data.requestType}
                  setIsModalOpen={setIsModalOpen}
                  showHoverButtons={showHoverButtons}
                  stateActions={stateActions}
                  userEmail={currentUserLoggedEmail}
                  isUserAdmin={isAdminUserRole}
                  modalOpenValues={modalOpenValues}
                  setModalOpenValues={stateActions?.setModalOpenValues}
                />
                <TimeOffRequestActionsEmployeeDenyButtons
                  tableRequestType={data.requestType}
                  record={record}
                  setIsModalOpen={setIsModalOpen}
                  showHoverButtons={showHoverButtons}
                  stateActions={stateActions}
                  userEmail={currentUserLoggedEmail}
                  isUserAdmin={isAdminUserRole}
                  modalOpenValues={modalOpenValues}
                  setModalOpenValues={stateActions?.setModalOpenValues}
                />

                {/* ! ------------------------------------------ ! */}

                {numberOfAvailableDownloads === 1 && (
                  <>
                    {Object.keys(availableDownloads).map((key, index) => {
                      if (availableDownloads[key].check) {
                        return (
                          <ButtonWithExpandedText
                            key={index}
                            style={{ cursor: 'pointer' }}
                            // className="download"
                            embedSrc={<ThemedIcon icon={TimeOffDownloadIcon} />}
                            onClick={availableDownloads[key].action}
                            displayedText={
                              <>
                                <FormattedMessage id="download" />
                                <span
                                  style={{
                                    marginLeft: '4px',
                                    padding: '0'
                                  }}
                                  // className="download_file"
                                >
                                  <FormattedMessage
                                    id={availableDownloads[key].textId}
                                  />
                                </span>
                              </>
                            }
                          ></ButtonWithExpandedText>
                        );
                      }
                    })}
                  </>
                )}

                {numberOfAvailableDownloads > 1 && (
                  <div
                    className={`button-with-expanded-text ${params.theme}`}
                    style={{ cursor: 'default' }}
                  >
                    <ThemedIcon icon={TimeOffDownloadIcon} />
                    <span>
                      <div className="expand-container" style={{}}>
                        <FormattedMessage id="download" />
                        {Object.keys(availableDownloads).map((key, index) => {
                          if (availableDownloads[key].check) {
                            return (
                              <ButtonSimple
                                key={index}
                                type="link"
                                className="download"
                                //* To be changed soon: button animation from center or from the left?
                                style={{
                                  justifyContent: 'center',
                                  fontWeight: '500',
                                  padding: '0'
                                }}
                                onClick={availableDownloads[key].action}
                              >
                                <FormattedMessage
                                  id={availableDownloads[key].textId}
                                />
                              </ButtonSimple>
                            );
                          }
                        })}
                      </div>
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      }
    },
  ];

  if (allowPermision(role, [userRoles.Admin, userRoles.HR])) {
    tableColumns.push({
      title: <FormattedMessage id="table_ColleaguesRequestsCol_OrderNumber" />,
      dataIndex: 'orderNumber',
      width: '100px',
      responsive: ['xl'] as any,
      render: (_, record) => {

        return (
            <div style={{display: 'flex', gap: '1em'}}>
              <p style={{minWidth: '17px'}}>
                {record.orderNumber ? record.orderNumber : ''}
              </p>
              <p>
                <Tooltip
                    placement="top"
                    title={
                      <FormattedMessage
                          id={
                            record.orderNumberUpdatedOn
                                ? 'OrderGeneratedWarningTitle'
                                : 'OrderGeneratedCheckTitle'
                          }
                          values={{
                            date: formatedData(record.orderNumberUpdatedOn || record.orderNumberCreatedOn, 'en-GB'),
                            time: formatedTime(record.orderNumberUpdatedOn || record.orderNumberCreatedOn, 'en-GB')
                          }}
                      />
                    }
                >
                  {record.orderNumberUpdatedOn ? (
                      <WarningOutlined style={{color: '#F2C94CFF'}}/>
                  ) : record.orderNumberCreatedOn ? (
                      <CheckOutlined style={{color: '#219653'}}/>
                  ) : (
                      ''
                  )}
                </Tooltip>
              </p>
            </div>
        );
      }
    });
  }

  const checkTypeForTeams = (column: any) =>
      data?.requestType === 'personal'
          ? !['teams', 'requestedForEmployee'].includes(column.dataIndex)
          : true;

  return [...tableColumns.filter(checkTypeForTeams)];
};

export const mobileTimeOffRequestColumns = (
    data,
    role,
    params,
    isModalOpen,
    setIsModalOpen,
    stateActions,
    dataSource,
    modalOpenValues
): ColumnsType<any> => [
  {
    title: <FormattedMessage id="table_ColleaguesRequestsCol_Requester"/>,
    dataIndex: 'requestedForEmployee',
    ...getColumnSearchProps(),
    render: (_, record) => {
      return (
          <p className="line-clamp-2">
            {record.requestedForEmployee.lastName +
                ' ' +
                record.requestedForEmployee.firstName}
          </p>
      );
    },
    width: '40%'
  },
  {
    title: <FormattedMessage id="table_ColleaguesRequestsCol_Period"/>,
    dataIndex: 'daysTaken',
    render: (_, record) => {
      let hoursResults;
      const requestTypes = record.requestType;
      const hourlyBasedTimeOffRequestType = [
        'PersonalLeave',
        'Overtime',
        'LeaveOffsetting'
      ].includes(requestTypes);
      if (hourlyBasedTimeOffRequestType) {
        hoursResults = computeHoursAndMinutes(record);
      } else {
        return (hoursResults = convertToLocalDate(record.endDate).isValid() ? (
          <p className={'line-clamp-2'}>{`${convertToLocalDate(
            record.startDate
          ).format('DD/MM')} - ${convertToLocalDate(record.endDate).format(
            'DD/MM'
          )}`}</p>
        ) : (
          <p className={'line-clamp-2'}>
            {convertToLocalDate(record.startDate).format('DD/MM')} -{' '}
            <FormattedMessage id="OngoingRequestText" />
          </p>
        ));
      }
      return (
        <p className={'line-clamp-2'}>
          {hoursResults},{' '}
          {hourlyBasedTimeOffRequestType &&
            convertToLocalDate(record?.startDate).format('DD/MM')}
        </p>
      );
    },
    width: '28%'
  },
  {
    title: <FormattedMessage id="table_ColleaguesRequestsCol_Type" />,
    dataIndex: 'publicName',
    render: (_, record) => {
      return (
        <p
          className={
            record.requestType.split(/(?=[A-Z])/).length === 1
              ? 'line-clamp-1'
              : 'line-clamp-2'
          }
        >
          <FormattedMessage id={record.requestType} />
        </p>
      );
    },
    width: 'auto'
  }
];

export const mobilePersonalTimeOffRequestColumns = (): ColumnsType<any> => [
  {
    title: <FormattedMessage id="table_ColleaguesRequestsCol_Type" />,
    dataIndex: 'publicName',
    render: (_, record) => {
      return (
        <p
          className={
            record.requestType.split(/(?=[A-Z])/).length === 1
              ? 'line-clamp-1'
              : 'line-clamp-2'
          }
        >
          <FormattedMessage id={record.requestType} />
        </p>
      );
    },
    width: 'auto'
  },
  {
    title: <FormattedMessage id="table_ColleaguesRequestsCol_Period" />,
    dataIndex: 'daysTaken',
    render: (_, record) => {
      let hoursResults;
      const requestTypes = record.requestType;
      const hourlyBasedTimeOffRequestType = [
        'PersonalLeave',
        'Overtime',
        'LeaveOffsetting'
      ].includes(requestTypes);
      if (hourlyBasedTimeOffRequestType) {
        hoursResults = computeHoursAndMinutes(record);
      } else {
        return (hoursResults = convertToLocalDate(record.endDate).isValid() ? (
          <p className={'line-clamp-2'}>{`${convertToLocalDate(
            record.startDate
          ).format('DD/MM')} - ${convertToLocalDate(record.endDate).format(
            'DD/MM'
          )}`}</p>
        ) : (
          <p className={'line-clamp-2'}>
            {convertToLocalDate(record.startDate).format('DD/MM')} -{' '}
            <FormattedMessage id="OngoingRequestText" />
          </p>
        ));
      }
      return (
        <p className={'line-clamp-2'}>
          {!record.endDate ? (
            <FormattedMessage id="OngoingRequestText" />
          ) : (
            hoursResults
          )}
          ,{' '}
          {hourlyBasedTimeOffRequestType &&
            convertToLocalDate(record?.startDate).format('DD/MM')}
        </p>
      );
    },
    width: '28%'
  },
  {
    title: <FormattedMessage id="table_ColleaguesRequestsCol_Status" />,
    dataIndex: 'status',
    render: (_, record) => {
      return (
        <p
          className={
            record.status.split(/(?=[A-Z])/).length === 1
              ? 'line-clamp-1'
              : 'line-clamp-2'
          }
        >
          <FormattedMessage id={record.status} />
        </p>
      );
    },
    width: 'auto'
  }
];
