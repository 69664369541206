import autumnImage from '../Image/Autumn_Timily_min.webp';
import winterImage from '../Image/Winter_Timily_min.webp';
import springImage from '../Image/Spring_Timily_min.webp';
import summerImage from '../Image/Summer_Timily_min.webp';

const seasonImages: { [key: number]: string } = {
    0: winterImage, 1: winterImage, 11: winterImage,
    2: springImage, 3: springImage, 4: springImage,
    5: summerImage, 6: summerImage, 7: summerImage,
    8: autumnImage, 9: autumnImage, 10: autumnImage
};

export const changeBackgroundAuth = () : string => {
   const currentMonth: number = new Date().getMonth();

   return seasonImages[currentMonth] || winterImage;
}