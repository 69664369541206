import {useEffect, useRef, useState} from 'react';
import {TimePicker} from 'antd';
import dayjs, {Dayjs} from "dayjs";
import {TimeRangePickerModal} from "./TImeRangePickerModal";

import './index.less';

const {RangePicker} = TimePicker;

type TimePickerType = 'start' | 'end';

export default function TimeRangePicker(props) {
    const startTimeInputRef = useRef<HTMLInputElement>(null);
    const endTimeInputRef = useRef<HTMLInputElement>(null);

    const [startTime, setStartTime] = useState<Dayjs | null>(null);
    const [endTime, setEndTime] = useState<Dayjs | null>(null);
    const [openPicker, setOpenPicker] = useState<TimePickerType | null>(null);
    const [disabledPersonal, setDisabledPersonal] = useState<boolean>(false);

    const handleChange = (type: TimePickerType, time: Dayjs) => {
        if (type === "start") {
            setStartTime(time);
        } else if (type === "end") {
            setEndTime(time);
        }
    }

    const handleOpenPicker = (type: TimePickerType) => () => {
        setOpenPicker(type);
    };

    const handleCancel = (type: TimePickerType) => {
        setOpenPicker(null);
    };

    const handleOk = (type: TimePickerType) => {
        if (type === "start" && !startTime) {
            setStartTime(dayjs());
        } else if (type === "end" && !endTime) {
            setEndTime(dayjs());
        }

        const finalStartTime = type === "start" && !startTime ? dayjs() : startTime;
        const finalEndTime = type === "end" && !endTime ? dayjs() : endTime;

        if (finalStartTime && finalEndTime) {
            props.onChange([finalStartTime, finalEndTime]);
        }

        setOpenPicker(null);
    }

    useEffect(() => {
        const wrapper = document.querySelector(
            '#custom-ant-timerange-picker-wrapper'
        );

        const clearIcon = wrapper?.querySelector('.ant-picker-clear');

        const handleClear = () => {
            setStartTime(null);
            setEndTime(null);
            props.onChange([null, null]);
        };

        if (clearIcon) clearIcon.addEventListener('click', handleClear);

        return () => {
            if (clearIcon) clearIcon.removeEventListener('click', handleClear);
        }

    }, [props.onChange]);

    useEffect(() => {
        const wrapper = document?.querySelector(
            '#custom-ant-timerange-picker-wrapper'
        );

        const pickerInputsHTML = wrapper?.querySelectorAll(
            '.ant-picker-input > input'
        );

        if (pickerInputsHTML) {
            startTimeInputRef.current = pickerInputsHTML[0] as HTMLInputElement;
            endTimeInputRef.current = pickerInputsHTML[1] as HTMLInputElement;

            startTimeInputRef.current.addEventListener('click', () => handleOpenPicker('start')());
            endTimeInputRef.current.addEventListener('click', () => handleOpenPicker('end')());
        }

        // Cleanup
        return () => {
            startTimeInputRef.current?.removeEventListener('click', () => handleOpenPicker('start')());
            endTimeInputRef.current?.removeEventListener('click', () => handleOpenPicker('end')());
        };
    }, []);

    useEffect(() => {
        if (props.disabledDate) {
            const isDisabled = props.disabledDate(dayjs());

            setDisabledPersonal((prevDisabled) => {
                return prevDisabled || isDisabled;
            });
        }
    }, [props.disabledDate]);

    return (
        <div id="custom-ant-timerange-picker-wrapper">
            <RangePicker
                open={false}
                {...props}
                inputReadOnly={true}
                value={[startTime, endTime]}
            />
            <TimeRangePickerModal
                openPicker={openPicker}
                handleCancel={handleCancel}
                handleOk={handleOk}
                handleChange={handleChange}
                disabledPersonal={disabledPersonal}
                disabledTime={props.disabledTime}
            />
        </div>
    );
}