import { TimeOffRequests } from 'models/TimeManagement';
import { commonRequest } from 'services/generic';
import { IEmployeeStatistics } from '../EmployeeProvider/types';
import { TimeOffRequest } from './types';

export default class TimeOffProvider {
  async getTimeOffRequest(payload: {
    requestId: string | number;
    extended: boolean;
  }) {
    const payloadData = { payload };
    return await commonRequest(
      'POST',
      payloadData,
      'TimeOff/getTimeOffRequest'
    );
  }

  async getTimeOffRequestsByApprover(payload: string | number) {
    const payloadData = { payload };
    return await commonRequest(
      'POST',
      payloadData,
      'TimeOff/getTimeOffRequestsByApprover'
    );
  }

  async getAllTimeOffRequests(payload: TimeOffRequests) {
    const payloadData = { payload };
    return await commonRequest(
      'POST',
      payloadData,
      'TimeOff/getAllTimeOffRequests'
    );
  }

  async getMyTimeOffRequests(payload: TimeOffRequests) {
    const payloadData = { payload };
    return await commonRequest(
      'POST',
      payloadData,
      'TimeOff/getMyTimeOffRequests'
    );
  }

  async addTimeOffRequest(payload: FormData) {
    const payloadData = { payload };
    return await commonRequest<TimeOffRequest>(
      'POST',
      payloadData,
      'TimeOff/request'
    );
  }

  async addTimeOffRequestFor(payload: FormData) {
    const payloadData = { payload };

    return await commonRequest<TimeOffRequest>(
      'POST',
      payloadData,
      'TimeOff/requestFor'
    );
  }

  async updateTimeOffRequest(payload: FormData) {
    const payloadData = { payload };
    return await commonRequest<TimeOffRequest>(
      'POST',
      payloadData,
      'TimeOff/update'
    );
  }

  async getTimeOffStatistics() {
    return await commonRequest<IEmployeeStatistics>(
      'POST',
      null,
      'TimeOff/getTimeOffStatistics'
    );
  }

  async getTimeOffStatisticsByEmployeeId(payload: number) {
    const payloadData = { payload };
    return await commonRequest<IEmployeeStatistics>(
      'POST',
      payloadData,
      'TimeOff/getTimeOffStatisticsByEmployeeId'
    );
  }

  async submit(payload: FormData) {
    const payloadData = { payload };
    return await commonRequest('POST', payloadData, 'TImeOff/submit');
  }

  async cancel(parameters) {
    const payloadData = { parameters };
    return await commonRequest('POST', payloadData, 'TImeOff/cancel');
  }

  async approveTimeOff(parameters) {
    const payloadData = { parameters };
    return await commonRequest('POST', payloadData, 'TImeOff/approveTimeOff');
  }
  async sign(parameters) {
    const payloadData = { parameters };
    return await commonRequest('POST', payloadData, 'TImeOff/sign');
  }

  async deny(payload) {
    const payloadData = { payload };
    return await commonRequest('POST', payloadData, 'TImeOff/deny');
  }

  async revoke(payload) {
    const payloadData = { payload };
    return await commonRequest('POST', payloadData, 'TImeOff/revoke');
  }

  async approveRevoke(parameters) {
    const payloadData = { parameters };
    return await commonRequest('POST', payloadData, 'TImeOff/approveRevoke');
  }

  async confirm(parameters) {
    const payloadData = { parameters };
    return await commonRequest('POST', payloadData, 'TImeOff/confirm');
  }

  async getTimeOffRequestFile(parameters) {
    const payloadData = { parameters, customResponseType: 'arraybuffer' };
    return await commonRequest(
      'GET',
      payloadData,
      'TimeOff/GetTimeoffRequestFile'
    );
  }

  async getTimeOffDecisionFile(parameters) {
    const payloadData = { parameters, customResponseType: 'arraybuffer' };
    return await commonRequest(
      'GET',
      payloadData,
      'TimeOff/GetTimeoffDecisionFile'
    );
  }

  async getPolicyByName(parameters) {
    const payloadData = { parameters };
    return await commonRequest('GET', payloadData, 'TimeOff/getPolicyByName');
  }

  async getEmployeeTimeOffRequests(payload) {
    return await commonRequest(
      'POST',
      payload,
      'TimeOff/getEmployeeTimeOffRequests'
    );
  }

  async getCalendarMonth(payload: string) {
    const payloadData = { payload };
    return await commonRequest('POST', payloadData, 'TimeOff/getCalendarMonth');
  }

  async getRequestAttachmentUrl(parameters: object) {
    const payloadData = { parameters };
    return await commonRequest(
      'POST',
      payloadData,
      'TimeOff/GetRequestAttachmentUrl'
    );
  }
  async getAttendance(payload: any) {
    const payloadData = { payload };

    return await commonRequest('POST', payloadData, 'TimeOff/getEmployeeAttendance');
  }

  async generateOrderNumbers(payload: any) {
    const payloadData = { payload };
    return await commonRequest('POST', payloadData, 'TimeOff/generateOrderNumbers');
  }
}
