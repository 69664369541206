import { FormattedMessage } from 'react-intl';

export const colleagueRequestModalProps = {
  formKeyId: 'timeOffForm',
  requestType: 'colleagues',
  scope: 'requestFor',
  timeOffId: null,
  modalTitle: <FormattedMessage id="RequestTimeOffModalTitle" />,
  submitBtnText: <FormattedMessage id="request" />,
  cancelBtnText: <FormattedMessage id="cancel" />,
  additionalBtnText: <FormattedMessage id="sendUnfinished" />,
  displayFooterAdditionalButton: true,
  displayRequestButton: true,
};

export const colleagueRequestGenerateOrdersModalProps = {
  formKeyId: 'generateOrdersForm',
  requestType: 'colleaguess',
  scope: 'requestFor',
  timeOffId: null,
  modalTitle: <FormattedMessage id="SelectTheRangeForTheOrdersGeneration" />,
  submitBtnText: <FormattedMessage id="generate" />,
  cancelBtnText: <FormattedMessage id="cancel" />,
}