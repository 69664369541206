import { FC } from 'react';
import './authLayout.less';
import { changeBackgroundAuth } from "../../utils/changeBackgroundAuth";

const AuthLayout: FC<any> = ({ children }) => {
  const seasonBackground: string = changeBackgroundAuth();

  return (
    <div className="loginPage" style={{backgroundImage: `url(${seasonBackground})`}}>
      <div id="overlay"></div>
      <div className="loginTitle">
        Hey there,
        <br />
        log in to see what's new!
      </div>
      <div className="loginOptions">
        <div className="transparentBox">
          {/* Leaving it here temporary, as we are going to replace it by something else */}
          <svg
            width="169"
            height="60"
            viewBox="0 0 522 200"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M196.608 129.91C188.796 137.72 176.124 137.72 168.312 129.91L160.031 121.63V180C160.031 191.04 151.069 200 140.027 200C128.985 200 120.023 191.04 120.023 180V73.33C120.023 62.29 128.975 53.33 140.027 53.33C145.548 53.33 150.549 55.57 154.17 59.19L196.608 101.62C204.419 109.43 204.419 122.09 196.608 129.91Z"
              fill="#BDA7E8"
            />
            <path
              d="M140.027 40C151.075 40 160.031 31.0457 160.031 20C160.031 8.9543 151.075 0 140.027 0C128.979 0 120.023 8.9543 120.023 20C120.023 31.0457 128.979 40 140.027 40Z"
              fill="#7DCBE9"
            />
            <path
              d="M106.68 20C106.68 27.51 109.161 34.43 113.352 40H93.3479V180C93.3479 191.04 84.3862 200 73.344 200C62.3019 200 53.3402 191.04 53.3402 180V40H20.0038C8.96172 40 0 31.04 0 20C0 8.96 8.96172 0 20.0038 0H113.352C109.161 5.57 106.68 12.49 106.68 20Z"
              fill="#BDA7E8"
            />
            <path
              d="M273.882 40C284.93 40 293.886 31.0457 293.886 20C293.886 8.9543 284.93 0 273.882 0C262.835 0 253.879 8.9543 253.879 20C253.879 31.0457 262.835 40 273.882 40Z"
              fill="#64A51C"
            />
            <path
              d="M360.559 20V180C360.559 191.04 351.607 200 340.555 200C329.503 200 320.551 191.04 320.551 180V20C320.551 8.96 329.513 0 340.555 0C351.597 0 360.559 8.96 360.559 20Z"
              fill="#BDA7E8"
            />
            <path
              d="M293.886 73.33V180C293.886 191.04 284.925 200 273.882 200C262.84 200 253.879 191.04 253.879 180V121.62L245.587 129.91C237.776 137.72 225.113 137.72 217.302 129.91C209.48 122.09 209.48 109.43 217.302 101.62L259.74 59.19C263.36 55.57 268.361 53.33 273.882 53.33C284.925 53.33 293.886 62.29 293.886 73.33Z"
              fill="#BDA7E8"
            />
            <path
              d="M501.986 53.33C496.465 53.33 491.454 55.57 487.843 59.19L468.98 78.04L447.946 99.07L408.048 59.18C404.427 55.56 399.427 53.32 393.905 53.32C382.863 53.32 373.902 62.28 373.902 73.32C373.902 78.84 376.142 83.85 379.763 87.46L398.626 106.32L427.252 134.94V179.99C427.252 191.03 436.214 199.99 447.256 199.99C458.298 199.99 467.26 191.03 467.26 179.99V136.34L497.285 106.32L516.139 87.47C519.76 83.85 522 78.84 522 73.32C522 62.28 513.038 53.32 501.996 53.32L501.986 53.33Z"
              fill="#BDA7E8"
            />
            <path
              d="M413.909 73.33C413.909 84.37 404.948 93.33 393.905 93.33C388.384 93.33 383.383 91.09 379.763 87.47C376.142 83.86 373.902 78.85 373.902 73.33C373.902 62.29 382.863 53.33 393.905 53.33C399.427 53.33 404.427 55.57 408.048 59.19C411.669 62.8 413.909 67.81 413.909 73.33Z"
              fill="#DC1F7A"
            />
            <path
              d="M521.99 73.33C521.99 78.85 519.75 83.86 516.129 87.47C512.518 91.09 507.517 93.33 501.996 93.33C490.954 93.33 481.992 84.37 481.992 73.33C481.992 67.81 484.233 62.8 487.853 59.19C491.464 55.57 496.475 53.33 501.996 53.33C513.038 53.33 522 62.29 522 73.33H521.99Z"
              fill="#FCBC08"
            />
          </svg>

          {children}
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
