import { FormattedMessage } from 'react-intl';

export const getSuccessMessages = {
  success_updated_profile: <FormattedMessage id="success_updated_profile" />,
  success_added_profile: <FormattedMessage id="success_added_profile" />,
  success_updated_user: <FormattedMessage id="success_updated_user" />,
  success_added_team: <FormattedMessage id="success_added_team" />,
  success_updated_team: <FormattedMessage id="success_updated_team" />,
  success_clock_out: <FormattedMessage id="success_clock_out" />,
  success_approved_request: <FormattedMessage id="success_approved_request" />,
  success_sign_request: <FormattedMessage id="success_sign_request" />,
  success_denied_request: <FormattedMessage id="success_denied_request" />,
  success_canceled_request: <FormattedMessage id="success_canceled_request" />,
  success_revoked_request: <FormattedMessage id="success_revoked_request" />,
  success_confirmed_mark_request: (
    <FormattedMessage id="success_confirmed_mark_request" />
  ),
  success_approved_and_confirmed_request: (
    <FormattedMessage id="success_approved_and_confirmed_request" />
  ),
  success_revocation_request: (
    <FormattedMessage id="success_revocation_request" />
  ),
  success_revocation_request_confirmed: (
    <FormattedMessage id="success_revocation_request_confirmed" />
  ),
  success_revocation_request_denied: (
    <FormattedMessage id="success_revocation_request_denied" />
  ),
  success_added_asset: <FormattedMessage id="success_added_asset" />,
  success_edited_asset: <FormattedMessage id="success_edited_asset" />,
  success_added_category: <FormattedMessage id="success_added_category" />,
  success_edited_category: <FormattedMessage id="success_edited_category" />,
  success_removed_category: <FormattedMessage id="success_removed_category" />,
  success_submited_request: <FormattedMessage id="success_submited_request" />,
  success_draft_request: <FormattedMessage id="success_draft_request" />,
  success_clocked_in: <FormattedMessage id="success_clocked_in" />,
  success_assign_colleague: <FormattedMessage id="success_assign_colleague" />,
  success_assign_team: <FormattedMessage id="success_assign_team" />,
  success_deassign_asset: <FormattedMessage id="success_deassign_asset" />,
  success_member_added: <FormattedMessage id="success_member_added" />,
  success_member_removed: <FormattedMessage id="success_member_removed" />,
  success_preset_saved: <FormattedMessage id="success_preset_saved" />,
  success_preset_edited: <FormattedMessage id="success_preset_edited" />,
  success_preset_deleted: <FormattedMessage id="success_preset_deleted" />,
  success_updated_draft_request: (
    <FormattedMessage id="success_updated_draft_request" />
  ),
  success_updated_request: <FormattedMessage id="success_updated_request" />,
  success_conflict_resolved: (
    <FormattedMessage id="success_conflict_resolved" />
  ),

  succes_added_work_queue: <FormattedMessage id="success_added_work_queue" />,
  succes_edit_work_queue: <FormattedMessage id="success_edited_work_queue" />,
  success_removed_work_queue: (
    <FormattedMessage id="success_removed_work_queue" />
  ),
  succes_added_worker: <FormattedMessage id="success_added_worker" />,
  success_removed_worker: <FormattedMessage id="success_removed_worker" />,
  succes_added_configuration: (
    <FormattedMessage id="success_added_configuration" />
  ),
  success_removed_configuration: (
    <FormattedMessage id="success_removed_configuration" />
  ),

  success_added_subcategory: (
    <FormattedMessage id="success_added_subcategory" />
  ),
  success_edited_subcategory: (
    <FormattedMessage id="success_edited_subcategory" />
  ),
  success_removed_subcategory: (
    <FormattedMessage id="success_removed_subcategory" />
  ),
  success_updated_platform_settings: (
    <FormattedMessage id="Settings_update_success_message" />
  ),

  success_added_contact: <FormattedMessage id="success_added_contact" />,
  success_edited_contact: <FormattedMessage id="success_edited_contact" />,
  success_removed_contact: <FormattedMessage id="success_removed_contact" />,
  success_generate_orders: <FormattedMessage id="success_generate_orders" />,
};
