import {
  useMobileContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';
import { useModalContext } from 'components/Modal/ModalProvider';
import DatePickerComponent from 'components/UIComponents/DatePickerSelect/DatePickerComponent';
import SearchToolbarComponent from 'components/UIComponents/SearchToolbar';
import SingleSelectDropdown from 'components/UIComponents/SingleSelectDropdown';
import { TimeOffColleaguesActions } from 'context/TimeManagement/ColleaguesRequests/actions';
import { useColleaguesRequestsFilterContext } from 'context/TimeManagement/ColleaguesRequests/context';
import dayjs from 'dayjs';
import useTimeOffPolicies from 'hooks/useTimeOffPolicies';
import useOutsideClick from 'hooks/useOutsideClick.hook';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  allowPermision,
  getCurrentUserRole,
  userRoles
} from 'utils/userManagement';
import OptionButton from 'components/UIComponents/OptionButton';
import { colleagueRequestModalProps, colleagueRequestGenerateOrdersModalProps } from '../types';
import { RequestDateFilterOptions, RequestStatusFilterOptions } from './utils';
import MultipleSelect from 'components/UIComponents/Dropdown/MultipleSelect';

import './ColleaguesTimeOffToolbar.less';

const ColleaguesTimeOffToolbar: React.FC<any> = props => {
  const { user, setModalProps } = props;

  const intl = useIntl();
  const { theme } = useThemeContext();
  const { isMobile } = useMobileContext();
  const { toggleModal } = useModalContext();
  const { timeOffColleaguesState, timeOffColleaguesDispatch } =
    useColleaguesRequestsFilterContext();
  const { mapPolicies } = useTimeOffPolicies();

  const [dateRangeFilterValue, setDateRangeFilterValue] = React.useState(null);

  const onCalendarChange = (dates, filterTypeName: string, filter: string) => {
    setDateRangeFilterValue(dates);

    if (dates === null) {
      timeOffColleaguesDispatch({
        type: TimeOffColleaguesActions.UPDATE_TOOLBAR_PERIOD_FILTER,
        payload: {
          requestId: null,
          filterBy: {
            period: {
              type: RequestDateFilterOptions.AllPeriods,
              startDate: null,
              endDate: null
            }
          },
          filters: {
            period: {
              type: RequestDateFilterOptions.AllPeriods,
              startDate: null,
              endDate: null
            }
          }
        }
      });
    } else {
      if (dates && dates.length > 1 && dates[0] && dates[1]) {
        const startDate = dayjs
          .utc(dayjs(dates[0]).format('YYYY-MM-DD'))
          .toISOString();
        const endDate = dayjs
          .utc(dayjs(dates[1]).format('YYYY-MM-DD'))
          .toISOString();
        timeOffColleaguesDispatch({
          type: TimeOffColleaguesActions.UPDATE_TOOLBAR_PERIOD_FILTER,
          payload: {
            requestId: null,
            filterBy: {
              period: {
                type: RequestDateFilterOptions.TimeRange,
                startDate: startDate,
                endDate: endDate
              }
            },
            filters: {
              period: {
                type: RequestDateFilterOptions.TimeRange,
                startDate: startDate,
                endDate: endDate
              }
            }
          }
        });
      }
    }
  };

  const requestTypeOptionData = mapPolicies();

  const AllStatuses = { SelectedAllStatuses: 'AllStatuses' };
  const AllTypes = { SelectedAllTypes: 'AllTypes' };

  const [isDropped, setIsDropped] = React.useState<boolean>(false);
  const [isPickerOpen, setIsPickerOpen] = React.useState(false);

  const ddRef = React.useRef<HTMLFieldSetElement | null>(null);
  useOutsideClick(
    ddRef,
    () => isDropped && !isPickerOpen && setIsDropped(false)
  );
  const requestStatusLabel = intl.formatMessage({ id: 'RequestStatus' });
  const requestTypeLabel = intl.formatMessage({ id: 'RequestType' });
  const filterByLabel = intl.formatMessage({ id: 'FilterBy' });
  const generateLabelStatuses = () => {
    const { filterBy } = timeOffColleaguesState;
    const { customStatuses } = filterBy || {};

    const totalStatuses = Object.keys(RequestStatusFilterOptions)?.length;
    const totalSelectedStatuses = customStatuses?.length ?? totalStatuses;
    const selectionRatio = `${totalSelectedStatuses}/${totalStatuses}`;

    if (totalSelectedStatuses === 0) return `${filterByLabel}: ${requestStatusLabel}`;
    if (totalSelectedStatuses === 1) return `${requestStatusLabel}: ${intl.formatMessage({ id: customStatuses[0] })} ${selectionRatio}`;
    if (totalSelectedStatuses === null || totalSelectedStatuses > 1) return `${requestStatusLabel}: ${selectionRatio}`;
  };
  const generateLabelPolicies = () => {
    const { filterBy } = timeOffColleaguesState;
    const { policies } = filterBy || {};

    const totalPolicies = Object.keys(requestTypeOptionData)?.length;
    const totalSelectedPolicies = policies?.length ?? totalPolicies;
    const selectionRatio = `${totalSelectedPolicies}/${totalPolicies}`;

    if (totalSelectedPolicies === 0) return `${filterByLabel}: ${requestTypeLabel}`;
    if (totalSelectedPolicies === 1) return `${requestTypeLabel}: ${intl.formatMessage({ id: policies[0] })} ${selectionRatio}`;
    if (totalSelectedPolicies === null || totalSelectedPolicies > 1) return `${requestTypeLabel}: ${selectionRatio}`;
  };

  return (
    <SearchToolbarComponent
      key="ColleaguesTimeOffRequests"
      displaySearchIcon={false}
      displaySearchInput={false}
      children={
        <div className="search-toolbar_filters_container">
          <MultipleSelect
            theme={theme}
            label={generateLabelStatuses()}
            options={Object.keys(RequestStatusFilterOptions).map(option => ({
              value: option,
              label: intl.formatMessage({ id: option })
            }))}
            defaultOptions={timeOffColleaguesState?.filterBy?.customStatuses}
            dropdownAlign={{ offset: [53, -14] }}
            allOption={{
              value: AllStatuses.SelectedAllStatuses,
              label: intl.formatMessage({ id: AllStatuses.SelectedAllStatuses })
            }}
            onOptionChange={options => {
              timeOffColleaguesDispatch({
                type: TimeOffColleaguesActions.UPDATE_TOOLBAR_STATUS_FILTER,
                payload: {
                  requestId: null,
                  filterBy: { customStatuses: options },
                  filters: {
                    customStatuses: options
                  }
                }
              });
            }}
          />

          <MultipleSelect
            theme={theme}
            label={generateLabelPolicies()}
            dropdownAlign={{ offset: [54, 0] }}
            defaultOptions={timeOffColleaguesState?.filterBy?.policies}
            options={Object.keys(requestTypeOptionData).map(option => ({
              value: option,
              label: intl.formatMessage({ id: option })
            }))}
            allOption={{
              value: AllTypes.SelectedAllTypes,
              label: intl.formatMessage({ id: AllTypes.SelectedAllTypes })
            }}
            onOptionChange={(option) => {
              timeOffColleaguesDispatch({
                type: TimeOffColleaguesActions.UPDATE_TOOLBAR_POLICY_FILTER,
                payload: {
                  requestId: null,
                  filterBy: { policies: option },
                  filters: { policies: option }
                }
              });
            }}
          ></MultipleSelect>

          {/* Request Date Filters */}
          <SingleSelectDropdown
            className="dd-colleagues-date-range period-range"
            dropdownAlign={{ offset: [53, isMobile ? null : 0] }}
            label={`${intl.formatMessage({ id: 'DatePeriod' })}: ${timeOffColleaguesState?.filterBy?.period?.type !== 'TimeRange'
              ? intl.formatMessage({
                id: timeOffColleaguesState?.filterBy?.period?.type
              })
              : !isMobile
                ? `${dayjs(
                  timeOffColleaguesState?.filterBy?.period?.startDate
                ).format('DD/MM/YYYY')} - ${dayjs(
                  timeOffColleaguesState?.filterBy?.period?.endDate
                ).format('DD/MM/YYYY')}`
                : `${dayjs(
                  timeOffColleaguesState.filterBy?.period?.startDate
                ).format(
                  'DD/MM'
                )} - ${dayjs(timeOffColleaguesState.filterBy?.period?.endDate).format('DD/MM')}`
              }`}
            isDropped={isDropped}
            ref={ddRef}
            theme={theme}
            value={timeOffColleaguesState?.filterBy?.period?.type}
            onDropChange={() => setIsDropped(prevIsDropped => !prevIsDropped)}
          >
            <>
              {Object.keys(RequestDateFilterOptions).map(option => (
                <li
                  className={`dd__item ${option === timeOffColleaguesState?.filterBy?.period?.type
                    ? 'dd__item--selected'
                    : ''
                    }`}
                  key={option}
                >
                  {option === 'TimeRange' ? (
                    <>
                      <DatePickerComponent
                        onCalendarChange={onCalendarChange}
                      />
                    </>
                  ) : (
                    <OptionButton
                      value={intl.formatMessage({ id: option })}
                      type="radio"
                      checked={
                        option ===
                        timeOffColleaguesState?.filterBy?.period?.type
                      }
                      showShape={false}
                      theme={theme}
                      onChange={() => {
                        timeOffColleaguesDispatch({
                          type: TimeOffColleaguesActions.UPDATE_TOOLBAR_PERIOD_FILTER,
                          payload: {
                            requestId: null,
                            filterBy: {
                              period: {
                                type: option
                              }
                            },
                            filters: {
                              period: {
                                type: option
                              }
                            }
                          }
                        });
                        setIsDropped(!isDropped);
                      }}
                    />
                  )}
                </li>
              ))}
            </>
          </SingleSelectDropdown>
        </div>
      }
      buttons={[
        {
          text: <FormattedMessage id="RequestLeaveOnBehalfOfColleague" />,
          action: () => {
            setModalProps(colleagueRequestModalProps);
            toggleModal();
          },
          show: allowPermision(getCurrentUserRole(user), [
            userRoles.Admin,
            userRoles.HR
          ])
        },
        {
          text: <FormattedMessage id="GenerateOrders" />,
          action: () => {
            setModalProps(colleagueRequestGenerateOrdersModalProps);
            toggleModal();
          },
          show: allowPermision(getCurrentUserRole(user), [
            userRoles.Admin,
            userRoles.HR
          ])
        }
      ]}
    />
  );
};

export default ColleaguesTimeOffToolbar;
