import {
  useMobileContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';
import { BaseButton, ButtonSimple } from 'components/Buttons';
import ComponentWithStatus from 'components/ComponentWithStatus';
import { TableLayoutPage } from 'components/LayoutPageWithTitle.tsx';
import ModalComponent from 'components/Modal';
import ConfirmModalComponent from 'components/Modal/ConfirmModal';
import { useConfirmationModalContext } from 'components/Modal/ConfirmModal/ConfirmationModalProvider';
import { useModalContext } from 'components/Modal/ModalProvider';
import {
  SmallRequestsWidgetPlaceholder,
  TableComponentPlaceholder
} from 'components/Placeholders';
import TableComponent, { TableParams } from 'components/Table';
import dayjs from 'dayjs';
import { colleagueRequestModalProps, colleagueRequestGenerateOrdersModalProps } from 'pages/TimeManagement/ColleaguesTimeOffRequests/types';
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAuth } from 'services/providers/AuthProvider';
import { TimeOffRequest } from 'services/providers/TimeOffProvider/types';
import {
  adminRoles,
  allowPermision,
  getCurrentUserRole,
  userRoles
} from 'utils/userManagement';
import TimeOffRequestForm from '../TimeOffRequestForm';
import { getRequestTimeOffActionStatusDetails } from '../TimeOffRequestTable/RequestActionButton/index';
import ViewRequestForm from '../ViewRequestForm';
import ViewRequestButtons from '../ViewRequestForm/ViewRequestButtons';
import { IPreviousYears, PrevisiousYears } from './PrevisiousYersButton';
import {
  mobilePersonalTimeOffRequestColumns,
  mobileTimeOffRequestColumns,
  timeOffRequestTableColumns
} from './TimeOffRequestTableColumns';

const ButtonCustom = BaseButton(ButtonSimple);

interface TimeOffRequestTableFilter {
  text: string;
  value: number;
}

interface TimeOffRequestTableProps {
  requestType: string;
  setModalProps: Dispatch<SetStateAction<any>>;
  modalOpenValues?: any;
  setModalOpenValues?: Dispatch<SetStateAction<any>>;
  responseData?: TimeOffRequest[];
  totalCount?: number;
  resultSize?: number;
  page?: number;
  pageSize?;
  setPage?;
  onPaginationChange?;
  onNrItemsPerPageChange?;
  nextApproverFilter?: TimeOffRequestTableFilter[];
  teamsFilter?: TimeOffRequestTableFilter[];
  onChange?: (props: TableParams) => void;
}
//
const TimeOffRequestTable: FC<TimeOffRequestTableProps> = ({
  requestType,
  setModalProps,
  modalOpenValues,
  setModalOpenValues,
  responseData,
  totalCount,
  resultSize,
  page,
  setPage,
  onPaginationChange,
  onNrItemsPerPageChange,
  nextApproverFilter,
  teamsFilter,
  onChange
}) => {
  const intl = useIntl();
  const {
    toggleModal,
    setViewRequestModal,
    viewRequestModal,
    isTopRoleModalOpen,
    setIsTopRoleModalOpen
  } = useModalContext();
  const { setIsConfirmModalOpen, setOnOkModalPromise } =
    useConfirmationModalContext();
  const { isMobile, isTabletDevice, isSmallLaptop, deviceHeight } =
    useMobileContext();
  const { theme } = useThemeContext();
  const { user } = useAuth();
  const userRole = getCurrentUserRole(user);
  const isAdminUserRole = allowPermision(userRole, adminRoles);
  const isPersonalRequestsPage = requestType === 'personal';

  const [filteredResponseData, setFilteredResponseData] = useState<
    TimeOffRequest[]
  >([]);
  const [previousYearsResponseData, setPreviousYearsResponseData] = useState<
    TimeOffRequest[]
  >([]);
  const [searchParamsRequestId, setSearchParamsRequestId] = useState<
    number | null
  >(null);
  const [showPreviousYears, setShowPreviousYears] = useState<boolean>(false);
  const [requestModalProps, setRequestModalProps] = useState<any>({});
  const [statusDetails, setStatusDetails] = useState<any>();
  const [tableData, setTableData] = useState<any>(null);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [confirmModalProps, setConfirmModalProps] = useState<{
    title: string;
    message: string;
    okText?: string;
    cancelText?: string;
    messageSuccessfully?: string;
  }>({ title: null, message: null });

  const translateResponseData = (data: TimeOffRequest[]) => {
    if (data && data.length) {
      return data.map(request => ({
        ...request,
        requestType: intl.formatMessage({ id: request?.requestType || 'None' })
      }));
    }
    return [];
  };

  // query search parameters
  const queryParams = new URLSearchParams(window.location.search);

  // useEffect(() => {
  //   // in query search parameters requestId
  //   if (queryParams.has('requestId')) {
  //     setSearchParamsRequestId(
  //       queryParams.get('requestId') && parseInt(queryParams.get('requestId'))
  //     );
  //   } else {
  //     setSearchParamsRequestId(null);
  //   }
  // }, [window.location.search]);

  useEffect(() => {
    setFilteredResponseData(translateResponseData(responseData));

    if (!tableData || !tableData.requestType) {
      setTableData({
        requestType,
        user
      });
    }

    const updateTimeOffTableDataSource = () => {
      if (
        !responseData ||
        !totalCount ||
        responseData === filteredResponseData
      ) {
        return;
      }

      if (isPersonalRequestsPage) {
        const startOfYear = dayjs().startOf('year').toDate();
        const endOfYear = dayjs().endOf('year').toDate();

        const getRequestedDate = (request: TimeOffRequest) =>
          new Date(request.startDate);

        setFilteredResponseData(
          responseData?.filter(
            request =>
              getRequestedDate(request) >= startOfYear ||
              getRequestedDate(request) <= endOfYear
          )
        );

        setPreviousYearsResponseData(
          responseData?.filter(
            request => getRequestedDate(request) < startOfYear
          )
        );

        return;
      }

      let singleRequestFromRedirect = searchParamsRequestId
        ? responseData?.filter(
            requests => requests.requestId === searchParamsRequestId
          )
        : [];

      setFilteredResponseData(
        searchParamsRequestId ? singleRequestFromRedirect : responseData ?? []
      );

      window.history.replaceState(
        null,
        'colleaguesRequests',
        '/timeManagement/colleaguesRequests'
      );
    };

    updateTimeOffTableDataSource();
  }, [tableData, responseData]);

  const previousYearsParams: IPreviousYears = {
    requestType: tableData?.requestType,
    showPreviousYears: showPreviousYears,
    setShowPreviousYears: setShowPreviousYears
  };

  const getTableHeight = () => {
    const container = document.querySelector('.layout-page-with-title');
    let tableHeight: number;
    if (container) {
      tableHeight = container.clientHeight - 137;
    }

    return tableHeight;
  };

  const params = {
    isAdminUserRole,
    isTabletDevice,
    isSmallLaptop,
    theme
  };

  const tableComponentProps = {
    teamsFilter: teamsFilter,
    nextApproverFilter: nextApproverFilter,
    rowKey: 'requestId',
    rowClassName: 'hoverRow',
    role: userRole,
    params: params,
    data: tableData,
    columns: !isMobile
      ? timeOffRequestTableColumns
      : isPersonalRequestsPage
        ? mobilePersonalTimeOffRequestColumns
        : mobileTimeOffRequestColumns,
    setIsModalOpen: setIsConfirmModalOpen,
    style: {
      minHeight: isPersonalRequestsPage
        ? 'calc(100vh - 52em)'
        : 'calc(100vh - 22.2em)'
    },
    modalOpenValues,
    hookUseStateActions: {
      setOnOkModalPromise,
      setConfirmModalProps,
      setModalProps,
      toggleModal,
      setModalOpenValues,
      toggleComponentModal: toggleModal
    },
    onRow: isMobile
      ? record => {
          return {
            onClick: () => {
              setRequestModalProps({ ...record });
              setStatusDetails(
                getRequestTimeOffActionStatusDetails({ record })
              );
              setViewRequestModal(true);
            }
          };
        }
      : null
  };

  const tableRequestTitle = intl.formatMessage({
    id: 'table_ColleaguesRequestsTitle_Requests'
  });

  return (
    <>
      <ModalComponent
        isOpen={viewRequestModal}
        setIsOpen={setViewRequestModal}
        setIsEdit={setIsEdit}
        formKeyId="timeOffForm"
        title={<FormattedMessage id="RequestTimeOff" />}
        children={
          !isEdit ? (
            <ViewRequestForm
              modalProps={{ ...requestModalProps, statusDetails, isEdit }}
            />
          ) : (
            <TimeOffRequestForm
              requestType={requestModalProps.requestType}
              scope="request"
              timeOffId={requestModalProps.requestId}
              setIsEdit={setIsEdit}
              setViewRequestModal={setViewRequestModal}
            />
          )
        }
        displayFooterCloseButton={isEdit}
        displayFooterSubmitButton={isEdit}
        submitBtnText={<FormattedMessage id="save" />}
        footerButtons={
          !isEdit && (
            <ViewRequestButtons
              setIsEdit={setIsEdit}
              modalProps={requestModalProps}
              setConfirmModalProps={setConfirmModalProps}
            />
          )
        }
      />

      <ConfirmModalComponent
        title={confirmModalProps.title}
        confirmContent={confirmModalProps.message}
        okText={confirmModalProps.okText}
        cancelText={confirmModalProps.cancelText}
        setMessageSuccessfully={confirmModalProps.messageSuccessfully}
      />

      {tableData?.requestType && (
        <>
          <TableLayoutPage
            style={{
              height: isMobile
                ? isPersonalRequestsPage
                  ? `calc(${deviceHeight}px - 121px)`
                  : `calc(${deviceHeight}px - 185px)`
                : !isPersonalRequestsPage
                  ? `calc(${deviceHeight}px - 185px)`
                  : `100%`
            }}
            title={`${tableRequestTitle} (${
              totalCount === undefined ? 0 : totalCount
            })`}
            displayButton={
              requestType === 'colleagues' &&
              allowPermision(userRole, [userRoles.Admin, userRoles.HR])
            }
            childrenButton={
              <PrevisiousYears previousYearsParams={previousYearsParams} />
            }
          >
            <ComponentWithStatus
              status={responseData ? 'success' : 'pending'}
              Placeholder={
                isMobile ? (
                  <TableComponentPlaceholder count={3} />
                ) : isPersonalRequestsPage ? (
                  <SmallRequestsWidgetPlaceholder count={8} />
                ) : (
                  <TableComponentPlaceholder count={10} />
                )
              }
            >
              <TableComponent
                scroll={
                  !isMobile
                    ? {
                        x: 'calc(100vw - 220px)',
                        y: isPersonalRequestsPage
                          ? `${getTableHeight()}px`
                          : 'calc(100vh - 23em)'
                      }
                    : {
                        x: 'calc(100vw - 213px)',
                        y: isPersonalRequestsPage
                          ? `calc(${deviceHeight}px - 19.4em)`
                          : `calc(${deviceHeight}px - 24em)`
                      }
                }
                style={{
                  height: `calc(${deviceHeight}px - 32.7em)`
                  // minHeight: `calc(${deviceHeight} - 22.7em)`
                }}
                dataSource={responseData}
                data={responseData}
                {...tableComponentProps}
                className={`requestsTable${tableData?.requestType}`}
                page={page}
                setPage={setPage}
                pageSize={resultSize}
                onPaginationChange={onPaginationChange}
                onNrItemsPerPageChange={onNrItemsPerPageChange}
                totalItems={totalCount}
                onChange={(props: TableParams) => onChange(props)}
              />
            </ComponentWithStatus>
          </TableLayoutPage>

          <ModalComponent
            className="top-role-popup"
            setIsOpen={setIsTopRoleModalOpen}
            isOpen={isTopRoleModalOpen}
            displayFooter={false}
          >
            <section className="top-role-popup-buttons">
              <ButtonCustom
                onClick={() => {
                  setIsTopRoleModalOpen(false);
                  setModalProps(colleagueRequestModalProps);
                  toggleModal();
                }}
                className={`text-bold-normal btn-default-custom ${theme}`}
              >
                <FormattedMessage id="RequestLeaveOnBehalfOfColleague" />
              </ButtonCustom>
              <ButtonCustom
                  onClick={() => {
                    setIsTopRoleModalOpen(false);
                    setModalProps(colleagueRequestGenerateOrdersModalProps);
                    toggleModal();
                  }}
                  className={`text-bold-normal btn-default-custom ${theme}`}
              >
                <FormattedMessage id="GenerateOrders" />
              </ButtonCustom>
            </section>
          </ModalComponent>
        </>
      )}
      <br />
      {showPreviousYears && (
        <div style={{ paddingBottom: 16 }}>
          <TableLayoutPage
            title={`${intl.formatMessage({
              id: 'table_ColleaguesRequestsTitle_Requests'
            })} (${previousYearsResponseData?.length})`}
          >
            <TableComponent
              dataSource={previousYearsResponseData}
              {...tableComponentProps}
              className="previousRequestsTable"
            />
          </TableLayoutPage>
        </div>
      )}
    </>
  );
};

export default TimeOffRequestTable;
