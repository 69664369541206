import { useEffect, useState } from 'react';
import {Col, DatePicker, Form, Input, Row, Tooltip, Typography} from 'antd';
import ColFormItemComponent from 'components/UIComponents/Form/ColComponent';
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import './ViewRequestForm.less';
import { FormattedMessage, useIntl } from 'react-intl';
import { useThemeContext } from 'AppProvider/ConfigProviderSettings';
import { useModalContext } from 'components/Modal/ModalProvider';
import { convertToLocalDate } from 'components/DateParser';
import { computeHoursAndMinutes } from '../TimeOffRequestTable/utils';
import { ButtonSimple } from 'components/Buttons';
import { TimeOffService } from 'services';
import { isTimeBasedRequest } from '../TimeOffRequestForm/utils';
import {CheckOutlined, WarningOutlined} from "@ant-design/icons";

const SIMPLE_DATE_FORMAT = 'DD/MM/YYYY';

const ViewRequestForm = ({ modalProps }) => {
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const { Paragraph } = Typography;

  const navigate = useNavigate();
  const uploadedFiles = modalProps?.attachmentUrls
    ? modalProps?.attachmentUrls.split('©')
    : [];

  const dateFormat = 'DD/MM/YYYY';
  const getDateValue = date => {
    if (dayjs(date).format(SIMPLE_DATE_FORMAT) === 'Invalid Date') {
      return '-';
    } else {
      return dayjs(date).format(SIMPLE_DATE_FORMAT);
    }
  };

  const [requestsScope, setRequestsScope] = useState(null);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes('personalRequests')) {
      setRequestsScope('personal');
    } else {
      setRequestsScope('colleagues');
    }
  }, [location.pathname]);

  const startDate = convertToLocalDate(modalProps.startDate).format(
    SIMPLE_DATE_FORMAT
  );
  const endDate = convertToLocalDate(modalProps.endDate).format(
    SIMPLE_DATE_FORMAT
  );
  const submittedDate = convertToLocalDate(modalProps.createdDate).format(
    SIMPLE_DATE_FORMAT
  );

  const intl = useIntl();
  const { theme } = useThemeContext();

  let hoursResults;
  const requestTypes = modalProps.requestType;
  const hourlyBasedTimeOffRequestType = [
    'PersonalLeave',
    'Overtime',
    'LeaveOffsetting'
  ].includes(requestTypes);

  if (hourlyBasedTimeOffRequestType) {
    hoursResults = computeHoursAndMinutes(modalProps);
  } else {
    hoursResults =
      modalProps.daysTaken > 0
        ? `${modalProps.daysTaken} ${
            modalProps.daysTaken === 1
              ? intl.formatMessage({ id: 'day' })
              : intl.formatMessage({ id: 'days' })
          }`
        : intl.formatMessage({ id: 'OngoingRequestText' });
  }

  const { setViewRequestModal } = useModalContext();

  return (
    // REFACTOR VIEW REQUEST FORM

    <Form
      form={form}
      id="viewRequestForm"
      key="viewRequestFormKey"
      labelCol={{ span: 6 }}
      colon={false}
      // WILL BE MANAGED FROM MODAL
      disabled={true}
    >
      {requestsScope === 'colleagues' && (
        <>
          <ColFormItemComponent
            isReadOnly={`col-form-item-component-view ${theme}`}
            span={24}
            label={<FormattedMessage id="label_ViewReqyestMOBForm_requester" />}
            onClick={() => {
              setViewRequestModal(false);
              navigate(
                `/allUsersList/viewProfile?employeeId=${modalProps.requestedForEmployee.employeeId}`
              );
            }}
          >
            <Input
              className="requester-input-field"
              disabled
              value={
                modalProps?.requestedForEmployee?.lastName +
                ' ' +
                modalProps?.requestedForEmployee?.firstName
              }
            />
          </ColFormItemComponent>
          <ColFormItemComponent
            isReadOnly={`col-form-item-component-view ${theme}`}
            span={24}
            label={<FormattedMessage id="teams" />}
          >
            <TextArea
              autoSize={true}
              minLength={1}
              maxLength={51}
              disabled
              value={Object.values(
                modalProps?.requestedForEmployee?.teams || {}
              )
                .map(teamName => teamName)
                .join(', ')}
            />
          </ColFormItemComponent>
        </>
      )}

      {/* NEXT APPROVER FIELD */}

      <ColFormItemComponent
        isReadOnly={`col-form-item-component-view ${theme}`}
        span={24}
        label={<FormattedMessage id="label_ViewReqyestMOBForm_next_approver" />}
      >
        <TextArea
          autoSize={true}
          minLength={1}
          maxLength={51}
          disabled
          value={modalProps?.statusDetails}
        />
      </ColFormItemComponent>

      {/* TOR FIELD */}

      <ColFormItemComponent
        isReadOnly={`col-form-item-component-view ${theme}`}
        span={24}
        label={<FormattedMessage id="label_ViewReqyestMOBForm_type" />}
      >
        <Input
          disabled
          value={intl.formatMessage({
            id: modalProps?.publicName || 'EmptyIdText'
          })}
        />
      </ColFormItemComponent>

      {/* AMOUNT FIELD */}

      <ColFormItemComponent
        isReadOnly={`col-form-item-component-view ${theme}`}
        span={24}
        label={<FormattedMessage id="label_ViewReqyestMOBForm_Amount" />}
      >
        <Input
          disabled
          value={
            hourlyBasedTimeOffRequestType
              ? `${hoursResults}, ${convertToLocalDate(
                  modalProps.startDate
                ).format('HH:mm')} - ${convertToLocalDate(
                  modalProps.endDate
                ).format('HH:mm')} `
              : hoursResults
          }
        />
      </ColFormItemComponent>

      {/* START DATE FIELD */}

      <ColFormItemComponent
        span={24}
        // name="startDate"
        label={<FormattedMessage id="label_ViewReqyestMOBForm_From" />}
        isReadOnly={`col-form-item-component-view ${theme}`}
      >
        <Input value={startDate} />
        {/* <DatePicker
          defaultValue={dayjs(modalProps.startDate)}
          // inputReadOnly={true}
          getPopupContainer={(container) => container}
          className={`col-form-item-component ${theme}`}
          style={{ width: '100%' }}
          format={dateFormat}
        /> */}
      </ColFormItemComponent>

      {/* END DATE FIELD */}

      <ColFormItemComponent
        span={24}
        // name="endDate"
        label={<FormattedMessage id="label_ViewReqyestMOBForm_Until" />}
        isReadOnly={`col-form-item-component-view ${theme}`}
      >
        <Input
          value={
            dayjs(endDate) && modalProps.endDate
              ? endDate
              : intl.formatMessage({ id: 'OngoingRequestText' })
          }
        />
        {/* <DatePicker
          defaultValue={dayjs(modalProps.endDate)}
          // inputReadOnly={true}
          getPopupContainer={(container) => container}
          className={`col-form-item-component ${theme}`}
          style={{ width: '100%' }}
          format={dateFormat}
        /> */}
      </ColFormItemComponent>

      {/* STATUS FIELD */}

      <ColFormItemComponent
        isReadOnly={`col-form-item-component-view ${theme}`}
        span={24}
        label={<FormattedMessage id="label_ViewReqyestMOBForm_Status" />}
      >
        <Input
          disabled
          value={intl.formatMessage({
            id: modalProps?.status || 'EmptyIdText'
          })}
        />
      </ColFormItemComponent>

      {/* DETAILS FIELD */}

      {modalProps.details && (
        <ColFormItemComponent
          isReadOnly={`col-form-item-component-view ${theme}`}
          span={24}
          label={<FormattedMessage id="label_ViewReqyestMOBForm_Details" />}
        >
          <Input disabled value={modalProps.details || '-'} />
        </ColFormItemComponent>
      )}

      {/* ADDITIONAL FIELDS */}

      {modalProps.additionalFields &&
        modalProps.additionalFields.map(field => {
          const { name, value } = field;

          // Do not display fields with object as values
          if (name === 'timeRange') return null;

          return (
            <ColFormItemComponent
              span={24}
              name={name}
              label={<FormattedMessage id={name} />}
              isReadOnly={`col-form-item-component-view ${theme}`}
            >
              <Input
                defaultValue={
                  Date.parse(value)
                    ? new Date(value).toLocaleDateString('en-GB')
                    : value || '-'
                }
              />
            </ColFormItemComponent>
          );
        })}

      {/* NOTES FIELD */}

      {modalProps.notes && (
        <ColFormItemComponent
          span={24}
          name="notes"
          label={<FormattedMessage id="label_ViewReqyestMOBForm_Notes" />}
          isReadOnly={`col-form-item-component-view ${theme}`}
        >
          <TextArea defaultValue={modalProps.notes} />
        </ColFormItemComponent>
      )}
      <ColFormItemComponent
        isReadOnly={`col-form-item-component-view ${theme}`}
        span={24}
        label={<FormattedMessage id="label_ViewReqyestMOBForm_SubmittedOn" />}
      >
        <Input disabled value={submittedDate || '-'} />
      </ColFormItemComponent>

      {/* ORDER NUMBER */}
      <ColFormItemComponent
          isReadOnly={`col-form-item-component-view ${theme}`}
          span={24}
          label={<FormattedMessage id="table_ColleaguesRequestsCol_OrderNumber"/>}>
        <div style={{display: "flex", gap: "1em", height: "20px"}}>
          <p style={{minWidth: "17px", display: "flex", margin: 0}}>
            {modalProps.orderNumber ?? ""}
          </p>
          <Tooltip
              placement="top"
              title={intl.formatMessage({
                id: modalProps.orderNumberUpdatedOn
                    ? "OrderGeneratedWarningTitle"
                    : "OrderGeneratedCheckTitle"
              })}>
            {modalProps.orderNumberUpdatedOn ? (
                <WarningOutlined style={{color: "#F2C94CFF"}}/>
            ) : modalProps.orderNumberCreatedOn ? (
                <CheckOutlined style={{color: "#219653"}}/>
            ) : (
                <Input disabled value=""/>
            )}
          </Tooltip>
        </div>
      </ColFormItemComponent>

      {/* 
      // TODO: the admin should view all attachments in a request, and should be able to download such;
      */}

      {/* If no files were uploaded -> display nothing. */}
      {uploadedFiles.length > 0 && (
          <Row gutter={[12, 12]}>
            {uploadedFiles.map((file: string, index: number) => {
              const fileName = file.split('/').at(-1);

              return (
                  <Col span={24} key={index}>
                    <ButtonSimple
                        disabled={false}
                        className="file-container"
                        onClick={async (
                            event: React.ChangeEvent<HTMLInputElement>
                  ): Promise<any> => {
                    event.preventDefault();

                    await TimeOffService.getRequestAttachmentUrl({
                      requestId: modalProps?.requestId,
                      fileOrder: index
                    })
                      .then(response => {
                        var a = document.createElement('a');

                        a.href = response.data as string;
                        a.target = '_parent';

                        if ('download' in a) {
                          a.download = fileName;
                        }

                        (document.body || document.documentElement).appendChild(
                          a
                        );

                        if (a.click) {
                          a.click();
                        }
                        a.parentNode.removeChild(a);
                      })
                      .catch(error => {
                        throw new Error(
                          `Couldn't get files for recordId ${modalProps?.requestId}`
                        );
                      });
                  }}
                  key={file}
                >
                  <Paragraph
                    style={{
                      width: fileName.length > 11 ? '100px' : 'auto',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: 'inline-block',
                      verticalAlign: 'top',
                      color: '#FFFFFF'
                    }}
                    ellipsis={true}
                  >
                    {fileName}
                  </Paragraph>
                </ButtonSimple>
              </Col>
            );
          })}
        </Row>
      )}
    </Form>
  );
};

export default ViewRequestForm;
