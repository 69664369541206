import * as React from "react";
import ContentLoader from 'react-content-loader';

interface TimePickerSkeletonProps {
    speed?: number;
    width?: string | number;
    height?: string | number;
    backgroundColor?: string;
    foregroundColor?: string;
}

export const TimePickerSkeleton: React.FC<TimePickerSkeletonProps> = (props) => {
    return (
    <ContentLoader
        speed={1}
        width={'100%'}
        height={'384px'}
        backgroundColor={props.backgroundColor || '#ffffff33'}
        foregroundColor={props.foregroundColor || '#BDA7E833'}
        {...props}
    >
        <rect x="35%" y="20%" ry="10px" rx="10px" width="30%" height="64px"/>
        <circle cx="50%" cy="70%" r="90" fill="#ccc"/>
    </ContentLoader>
);
}