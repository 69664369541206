import React, {Suspense, lazy, useState, useEffect} from 'react';
import {Modal} from 'antd';
import {useThemeContext} from 'AppProvider/ConfigProviderSettings';
import {TimePickerSkeleton} from "../TimePickerSkeleton";
import dayjs, {Dayjs} from "dayjs";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';

import '../index.less'

const LocalizationProvider = lazy(() =>
    import ('@mui/x-date-pickers/LocalizationProvider').then((module) => ({
        default: module.LocalizationProvider
    }))
);

const StaticTimePicker = lazy(() =>
    import ('@mui/x-date-pickers/StaticTimePicker').then((module) => ({
        default: module.StaticTimePicker
    }))
);

const DemoContainer = lazy(() =>
    import ('@mui/x-date-pickers/internals/demo').then((module) => ({
        default: module.DemoContainer
    }))
);

const DemoItem = lazy(() =>
    import ('@mui/x-date-pickers/internals/demo').then((module) => ({
        default: module.DemoItem
    }))
);

type TimePickerType = 'start' | 'end';

interface TimeRangePickerModalProps {
    openPicker: TimePickerType | null;
    handleCancel: (type: TimePickerType) => void;
    handleOk: (type: TimePickerType) => void;
    handleChange: (type: TimePickerType, time: Dayjs) => void;
    disabledPersonal: boolean;
    disabledTime: boolean;
}

export const TimeRangePickerModal: React.FC<TimeRangePickerModalProps> = ({
                                                                              openPicker,
                                                                              handleCancel,
                                                                              handleOk,
                                                                              handleChange,
                                                                              disabledPersonal,
                                                                              disabledTime,
                                                                          }) => {

    const {theme} = useThemeContext();
    const [initialTime, setInitialTime] = useState<Dayjs | null>(null);

    useEffect(() => {
        if (disabledPersonal) {
            setInitialTime(dayjs().startOf('minute').add(1, 'minute'));
        } else {
            setInitialTime(dayjs());
        }
    }, [disabledPersonal]);

    return (
        <Modal
            open={!!openPicker}
            onCancel={() => handleCancel(openPicker!)}
            onOk={() => handleOk(openPicker!)}
            centered
            closable={false}
            className={`custom-time-range-picker-modal ${theme}`}
            mask={false}
        >
            <Suspense fallback={<TimePickerSkeleton/>}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['StaticTimePicker']}>
                        <DemoItem>
                            <StaticTimePicker
                                key={openPicker}
                                className={`static-time-picker ${theme}`}
                                defaultValue={initialTime}
                                onChange={(time) => handleChange(openPicker!, time)}
                                slots={{actionBar: () => null}}
                                ampm={false}
                                orientation='portrait'
                                disablePast={disabledPersonal}
                                disableFuture={disabledTime}
                            />
                        </DemoItem>
                    </DemoContainer>
                </LocalizationProvider>
            </Suspense>
        </Modal>
    );
};
